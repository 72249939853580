import React, {useState} from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import './index.css';

import HomePage from "./pages/HomePage/HomePage";
import MyApplic from "./pages/MyApplic/MyApplic";
import Authorization from "./pages/Authorization/Authorization";
import Registration from "./pages/Registration/Registration";
import AccountPage from "./pages/AccountPage/AccountPage";
import PrivacyPolicy from "./pages/RulesPage/PrivacyPolicy/PrivacyPolicy";
import RulesPage from "./pages/RulesPage/RulesPage";
import TermsOfUse from "./pages/RulesPage/TermsOfUse/TermsOfUse";
import ListingPolicy from "./pages/RulesPage/ListingPolicy/ListingPolicy";
import ServicesAgreement from "./pages/RulesPage/ServicesAgreement/ServicesAgreement";

// const URL = "https://194.113.106.35/api"
const API_URL = "https://услуги24-7.рф/api"
export default API_URL

const App = () => {

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<HomePage/>}/>
                <Route path="/applications" element={<MyApplic/>}/>
                <Route path="/authorization" element={<Authorization/>}/>
                <Route path="/registration" element={<Registration/>}/>
                <Route path="/account" element={<AccountPage/>}/>
                <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
                <Route path="/rules" element={<RulesPage/>}/>
                <Route path="/terms" element={<TermsOfUse/>}/>
                <Route path="/listing-policy" element={<ListingPolicy/>}/>
                <Route path="/services-agreement" element={<ServicesAgreement/>}/>
                <Route path="*" element={<div>Error Page</div>}/>
            </Routes>
        </BrowserRouter>
    );
};

ReactDOM.createRoot(document.getElementById('main')).render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>
);