import React, { useState } from 'react';
import "./CopyableText.css"

function CopyableText({ text }) {
    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
        navigator.clipboard.writeText(text).then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 2000); // Сбрасываем статус через 2 секунды
        }).catch(err => {
            console.error('Ошибка при копировании текста: ', err);
        });
    };

    return (
        <div className="copyable-text">
            <span
                onClick={handleCopy}
                style={{cursor: 'pointer', textDecoration: 'none'}}
            >
                {text}
            </span>
            {copied && <span style={{ marginLeft: '10px', color: 'green' }}>Скопировано!</span>}
        </div>
    );
}

export default CopyableText;