import "./RegistrationLastPage.css"
import React, {useEffect, useState} from "react";
import axios from "axios";
import API_URL from "../../../index";

const RegistrationLastPage = ({type, tgURL, phone, code}) => {

    const tgDescriptionFirst = "Чтобы перейти в Telegram, нажмите кнопку ниже";
    const tgDescriptionTwo = "После нажатия кнопки “Начать” в Telegram-боте, нажмите кнопку ниже";
    const transition_tg = async () => {
        window.open(tgURL, '_blank', 'noopener,noreferrer');
    }

    const check = async () => {
        if (phone && code) {
            await axios.post(`${API_URL}/user/login`, {
                    "telephone_number": phone,
                    "code": code
                }
            ).then(response => {
                if (response.status === 200 || response.status === 201) {
                    window.location.href = "/"
                }
            })
                .catch((error) => {
                    console.log(error)
                })
        }
    }

    return (
        <>
            <div className="authorization-first">
                <h2 className="authorization-first__title">Регистрация</h2>
                <span className="authorization-last__description">
                    {type === "tg" && tgDescriptionFirst}
                </span>
                <button className="authorization-last__btn-type" onClick={transition_tg}>
                    {type === "tg" ? (
                        <img src="/tg-block-img.svg" alt="Button" className="authorization-last__btn-type-img"/>
                    ) : (
                        <img src="/phone-auth.svg" style={{borderRadius: "18px", border: "6px solid white"}}
                             alt="Button" className="authorization-last__btn-type-img"/>
                    )}
                </button>
                <span className="authorization-last__description">
                    {type === "tg" && tgDescriptionTwo}
                </span>
                {type === "tg" ? (
                    <button style={{padding: "12px 30px"}} className="block-reg-or-auth__btn"
                            onClick={check}>Проверить</button>
                ) :
                    // <div className="code-input-container">
                    //     {code.map((digit, index) => (
                    //         <input
                    //             key={index}
                    //             id={`code-input-${index}`}
                    //             type="text"
                    //             maxLength="1"
                    //             value={digit}
                    //             onChange={(e) => handleChange(e, index)}
                    //             onKeyDown={(e) => handleKeyDown(e, index)}
                    //             className="code-input"
                    //         />
                    //     ))}
                    // </div>
                    null
                }
            </div>
        </>
    )
}

export default RegistrationLastPage