import React, {useEffect, useState} from "react";
import "./HomePageForIsp.css"

import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore from 'swiper';
import {Navigation} from "swiper/modules";
import axios from "axios";
import API_URL from "../../../index";
import {Link} from "react-router-dom";
import CopyableText from "../../../components/CopyableText/CopyableText";
import TariffBlock from "../../../components/TariffBlock/TariffBlock";
import open_tariff_block from "../../../components/TariffBlock/open_tariff_block";
import {type} from "@testing-library/user-event/dist/type";

SwiperCore.use([Navigation]);

const HomePageForIsp = ({data, windowWidth, categories, login, city}) => {
    const [client, setClient] = useState({})
    const [btnType, setBtnType] = useState("")
    const [tariff, setTariff] = useState(false)
    const [selectedCategory, setSelectedCategory] = useState()
    const [subCategoriesList, setSubCategoriesList] = useState([]);
    const [selectedSubCategory, setSelectedSubCategory] = useState();
    const [applications, setApplications] = useState([])
    const [applicationsLength,] = useState(Array.from({length: 12}))
    const [newApplicationLength, setNewApplicationLength] = useState(0);

    const get_applications = async () => {
        if (!selectedCategory || !selectedSubCategory) return; // Выйти, если категория или подкатегория не выбраны
        try {
            if (login) {
                const response = await axios.get(`${API_URL}/application/get-applications?category=${selectedCategory}&subcategory=${selectedSubCategory}&offset=0`, {withCredentials: true});

                if (response.status === 200) {
                    const check = await check_category();
                    if (login && check) {
                        setTariff(true);
                        setApplications(response.data);
                        setNewApplicationLength(response.data["count_new"]);
                    } else {
                        setTariff(false);
                        setNewApplicationLength(response.data["count_new"] || 0);
                    }
                }
            }
        } catch (error) {
            console.error(error);
        }
    };


    const check_category = async () => {
        try {
            const response = await axios.get(`${API_URL}/payment/check-category?category=${selectedCategory}`, {withCredentials: true});

            if (response.status === 200) {
                return response.data !== null;
            }
        } catch (error) {
            console.log(error);
            return false; // Возвращаем false, если произошла ошибка
        }
    }

    const handleSlideChange = (swiper, type) => {
        const activeIndex = swiper.realIndex; // Используем realIndex, чтобы не учитывать дубли при включенном loop
        const adjustedIndex = (activeIndex % (type === "sub" ? subCategoriesList.length : categories.length)
                + (type === "sub" ? subCategoriesList.length : categories.length))
            % (type === "sub" ? subCategoriesList.length : categories.length);

        if (type === "sub") {
            setSelectedSubCategory(subCategoriesList[adjustedIndex].name);
        } else {
            setSelectedCategory(categories[adjustedIndex]);
        }
    };

    const userPhone = (user, type) => {
        setClient({
            name: user["name"],
            phone: user["telephone_number"],
            url_tg: `https://t.me/${user["telegram_username"]}`
        });
        setBtnType(type);
        open_client_info();
    };
    const open_client_info = () => {
        const section = document.querySelector(".client__info")
        const black = document.querySelector(".window-closer-applic")
        if (section.classList.contains("open")) {
            section.classList.remove("open")
            black.classList.remove("open");
        } else {
            section.classList.add("open")
            black.classList.add("open");
        }
    }

    const getDeclension = (count) => {
        // Последние две цифры числа
        const lastTwoDigits = count % 100;
        // Последняя цифра числа
        const lastDigit = count % 10;

        // Проверяем случаи, когда числа оканчиваются на 11-14
        if (lastTwoDigits >= 11 && lastTwoDigits <= 14) {
            return `${count} новых объявлений`;
        }

        // Определяем окончание в зависимости от последней цифры
        switch (lastDigit) {
            case 1:
                return `${count} новое объявление`;
            case 2:
            case 3:
            case 4:
                return `${count} новых объявления`;
            default:
                return `${count} новых объявлений`;
        }
    };

    useEffect(() => {
        if (selectedSubCategory === undefined) {
            if (data && categories && categories.length > 0) {
                setSelectedCategory(categories[0])
                setSubCategoriesList(data[0]["sub_categories"]);
                setSelectedSubCategory(data[0]["sub_categories"][0]["name"]);
            }
        } else {
            if (data && categories && categories.length > 0 && selectedCategory) {
                const category = data.find((item) => item["name"] === selectedCategory);
                if (category) {
                    setSubCategoriesList(category["sub_categories"]);
                    if (category["sub_categories"].length > 0) {
                        setSelectedSubCategory(category["sub_categories"][0]["name"]);
                    }
                }
            }
        }
    }, [categories, data, selectedCategory]);

    useEffect(() => {
        if (selectedCategory && selectedSubCategory) {
            const category = data.find((item) => item["name"] === selectedCategory);
            if (category) {
                const subCategory = category["sub_categories"].find((item) => item["name"] === selectedSubCategory)
                if (subCategory) {
                    get_applications()
                }
            }
        }
    }, [selectedSubCategory, selectedCategory, login, tariff])

    const duplicatedSlides = subCategoriesList.length < 3
        ? [...subCategoriesList, ...subCategoriesList]
        : subCategoriesList;

    return (
        <>
            <div className="homepage-for-isp">
                <div className="container homepage-container">
                    <div className="homepage__column">
                        {categories && (
                            <div className="homepage__category-list">
                                <Swiper
                                    className="swiper-container isp"
                                    slidesPerView={1}
                                    setWrapperSize={true}
                                    centeredSlidesBounds={true}
                                    // centeredSlides={true}
                                    direction={'horizontal'}
                                    navigation={{
                                        nextEl: '.swiper-button-next.isp',
                                        prevEl: '.swiper-button-prev.isp',
                                    }}
                                    pagination={{clickable: true}}
                                    loop={true}
                                    onSlideChange={(swiper) => handleSlideChange(swiper, "category")}
                                >
                                    {categories.map((slide, index) => (
                                        <SwiperSlide key={index} className="swiper-slide isp">
                                            <div className="slide-content isp">
                                                <h3 className="slide__title isp">{slide}</h3>
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                    <div className="swiper-button-prev isp">
                                        <img src="/new-imgs/Arrow13.svg" alt="Prev" className="swiper-img"/>
                                    </div>
                                    <div className="swiper-button-next isp">
                                        <img src="/new-imgs/Arrow14.svg" alt="Next" className="swiper-img-last"/>
                                    </div>
                                </Swiper>
                            </div>
                        )}
                        {subCategoriesList.length > 0 && (
                            <div className="homepage__category-list">
                                <Swiper
                                    className="swiper-container isp"
                                    slidesPerView={1}
                                    slidesPerGroup={3}
                                    setWrapperSize={true}
                                    centeredSlidesBounds={true}
                                    // centeredSlides={true}
                                    direction={'horizontal'}
                                    navigation={{
                                        nextEl: '.swiper-button-next',
                                        prevEl: '.swiper-button-prev',
                                    }}
                                    pagination={{clickable: true}}
                                    loop={true}
                                    onSlideChange={(swiper) => handleSlideChange(swiper, "sub")}
                                >
                                    {duplicatedSlides.map((slide, index) => (
                                        <SwiperSlide key={index} className="swiper-slide isp">
                                            <div className="slide-content isp">
                                                <h3 className="slide__title isp">{slide.name}</h3>
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                    <div className="swiper-button-prev isp">
                                        <img src="/new-imgs/Arrow13.svg" alt="Prev" className="swiper-img"/>
                                    </div>
                                    <div className="swiper-button-next isp">
                                        <img src="/new-imgs/Arrow14.svg" alt="Next" className="swiper-img-last"/>
                                    </div>
                                </Swiper>
                            </div>
                        )}
                        {newApplicationLength > 0 && (
                            <div className="new-applications">{getDeclension(newApplicationLength)}</div>
                        )}
                        <div className="catalog">
                            {applications.length > 0 && login && tariff ? (
                                <ul className="catalog-list">
                                    {applications.map((item, index) => (
                                        <li key={index} className="catalog-item">
                                            {item["images"] && item["images"][0] ? (
                                                <img src={`${API_URL}/api/static/${item["images"][0]["image"]["path"]}`}
                                                     alt="File"
                                                     className="applic__item-img"/>
                                            ) : (
                                                <div style={{height: 0}}></div>
                                            )}
                                            <h3 className="catalog-item__category">{selectedCategory}</h3>
                                            <h4 className="catalog-item__subcategory">{item["subcategory"]["name"]}</h4>
                                            <span className="catalog-item__description">
                                                {item["description"]}
                                            </span>
                                            <div className="buttons-row">
                                                <button className="phone-btn"
                                                        onClick={() => userPhone(item["user"], "phone")}>
                                                    <img src="/phone-btn.svg" alt="Phone"
                                                         className="phone-btn__img"/>
                                                </button>
                                                <hr/>
                                                <button className="tg-btn"
                                                        onClick={() => userPhone(item["user"], "tg")}>
                                                    <img src="/tg-btn.svg" alt="Telegram" className="tg-btn__img"/>
                                                </button>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            ) : (!login || !tariff) ? (
                                <ul className="catalog-list">
                                    {applicationsLength.map((_, index) => (
                                        <li key={index} className="catalog-item none">
                                            <h3 className="catalog-item__category">Категория</h3>
                                            <h4 className="catalog-item__subcategory">Подкатегория</h4>
                                            <span
                                                className="catalog-item__description">Описание Описание Описание</span>
                                            <div className="buttons-row">
                                                <button className="phone-btn">
                                                    <img src="/phone-btn.svg" alt="Phone"
                                                         className="phone-btn__img"/>
                                                </button>
                                                <hr/>
                                                <button className="tg-btn">
                                                    <img src="/tg-btn.svg" alt="Phone" className="tg-btn__img"/>
                                                </button>
                                            </div>
                                            <div className="pay__block">
                                                <button onClick={() => {
                                                    if (login) {
                                                        open_tariff_block();
                                                    } else {
                                                        window.location.href = "/authorization"
                                                    }
                                                }} className="pay__link">Оплатить
                                                    подписку
                                                </button>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            ) : (login && tariff) && applications.length <= 0 && (
                                <div style={{
                                    fontSize: 24,
                                    fontWeight: 600,
                                    color: "white",
                                    paddingTop: "40px",
                                    textAlign: "center"
                                }}>Пока нет объявлений</div>
                            )}
                        </div>
                        <section className="window-closer-applic" onClick={() => open_client_info()}></section>
                        <div className="client__info">
                            {btnType === "phone" ? (
                                <>
                                    <h4>Кликни, чтобы скопировать номер телефона:</h4>
                                    <CopyableText text={`${client["phone"]}`}/>
                                    <h3>{client["name"]}</h3>
                                </>
                            ) : btnType === "tg" && (
                                <>
                                    <h4>Кликни, чтобы перейти в чат Telegram:</h4>
                                    <div className="copyable-text">
                                        <Link target="_blank" to={`${client["url_tg"]}`}
                                              style={{cursor: 'pointer', textDecoration: 'none'}}>Telegram</Link>
                                    </div>
                                    <h3>{client["name"]}</h3>
                                </>
                            )}
                        </div>
                        <section className="window-closer-tariff" onClick={() => open_tariff_block()}></section>
                        <div className="tariff-block">
                            <TariffBlock category={selectedCategory} city={city}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomePageForIsp