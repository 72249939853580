import Header from "../../components/Header/Header";
import RegistrationFirstPage from "../Registration/RegistrationFirstPage/RegistrationFirstPage";
import BlockRegOrAuth from "../../components/BlockRegOrAuth/BlockRegOrAuth";
import BlockTG from "../../components/BlockTG/BlockTG";
import Footer from "../../components/Footer/Footer";
import "./Registration.css"
import axios from "axios";
import {useEffect, useState} from "react";
import API_URL from "../../index";

const Registration = () => {
    const windowWidth = window.innerWidth;
    const [cities, setCities] = useState()

    const listCity = async () => {
        try {
            await axios.get(`${API_URL}/application/get-cities?offset=0`)
                .then(response => {
                    if (response.status === 200) {
                        const cities = response.data;
                        const options = cities.map(city => city.name);
                        const sortedOptions = options.sort((a, b) => a.localeCompare(b))
                        setCities(sortedOptions)
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        listCity()
    }, [])

    return (
        <>
            <Header/>
            <div className="authorization">
                <div className="container authorization-container">
                    <div className="authorization__row">
                        {windowWidth > 780 && (
                            <ul className="authorization__promo-list">
                                <li className="authorization__promo-item">
                                    <span className="authorization__promo-info">Здесь может быть ваша реклама</span>
                                </li>
                                <li className="authorization__promo-item">
                                    <span className="authorization__promo-info">Здесь может быть ваша реклама</span>
                                </li>
                                <li className="authorization__promo-item">
                                    <span className="authorization__promo-info">Здесь может быть ваша реклама</span>
                                </li>
                            </ul>
                        )}
                        <RegistrationFirstPage options={cities}/>
                        <div className="authorization__info-column">
                            <div className="authorization__reg-block">
                                <BlockRegOrAuth
                                    title={"Уже есть аккаунт?"}
                                    btn={"Войти"}
                                />
                                <BlockTG/>
                            </div>
                            {windowWidth > 780 && (
                                <li className="authorization__promo-item">
                                    <span className="authorization__promo-info">Здесь может быть ваша реклама</span>
                                </li>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default Registration