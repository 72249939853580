import React, {useState} from "react";
import "./HomePageForClient.css"

import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore from 'swiper';
import {Navigation} from "swiper/modules";
import AddBlock from "../../../components/AddBlock/AddBlock";
import API_URL from "../../../index";

SwiperCore.use([Navigation]);

const HomePageForClient = ({windowWidth, categories, selectedCity, login}) => {

    const [selectedCategory, setSelectedCategory] = useState("")
    const [categoryName, setCategoryName] = useState("")
    const [selectedSubCategory, setSelectedSubCategory] = useState("")

    const open_add_applic = (index, slide_index) => {
        const section = document.querySelector(".add-applic")
        const black = document.querySelector(".window-closer-applic")
        if (index >= 0) {
            setSelectedCategory(categories[index]["sub_categories"]) //index
            setCategoryName(categories[index]["name"])
            setSelectedSubCategory(categories[index]["sub_categories"][slide_index]["name"])
        } else {
            setSelectedCategory(undefined)
            setCategoryName("")
            setSelectedSubCategory(undefined)
        }
        if (section.classList.contains("open")) {
            section.classList.remove("open");
            black.classList.remove("open");
        } else {
            section.classList.add("open");
            black.classList.add("open");
        }
    }

    return (
        <>
            <div className="homepage">
                <div className="container homepage-container">
                    {categories && (
                        <div className="homepage__column">
                            {categories.map((item, index) => (
                                <div style={{width: "100%"}} key={index}>
                                    <h2 className="category__title">{item["sub_categories"].length > 0 && item["name"]}</h2>
                                    <div className="homepage__category-list">
                                        <Swiper
                                            className="swiper-container"
                                            spaceBetween={2} // Задает расстояние между слайдами
                                            slidesPerView={"auto"} // Количество видимых слайдов
                                            slidesPerGroup={1}
                                            setWrapperSize={true}
                                            // centeredSlides={true}
                                            centeredSlidesBounds={true}
                                            // centeredSlidesBounds={item["sub_categories"].length < 5}
                                            // centeredSlides={item["sub_categories"].length < 5}
                                            direction={'horizontal'}
                                            navigation={{
                                                nextEl: '.swiper-button-next',
                                                prevEl: '.swiper-button-prev',
                                            }}
                                            loop={true}
                                            pagination={{clickable: true}}
                                            // breakpoints={{
                                            //     1501: {
                                            //         slidesPerView: 5,
                                            //     },
                                            //     1201: {
                                            //         slidesPerView: 4,
                                            //     },
                                            //     581: {
                                            //         slidesPerView: 3,
                                            //     },
                                            //     371: {
                                            //         slidesPerView: 2
                                            //     },
                                            //     200: {
                                            //         slidesPerView: 1
                                            //     }
                                            // }}
                                        >
                                            {item["sub_categories"] ? item["sub_categories"].map((slide, slide_index) => (
                                                <SwiperSlide key={slide_index} className="swiper-slide">
                                                    <div className="slide-content">
                                                        <img src={`${API_URL}/api/static/${slide["image"]["path"]}`}
                                                             alt={`Logo ${slide["name"]}`} className="slide__img"/>
                                                        <div className="slide-content__info">
                                                            <h3 className="slide__title">{slide["name"]}</h3>
                                                            <span
                                                                className="slide__description">{slide["description"]}</span>
                                                        </div>
                                                        <button className="slide__btn"
                                                                onClick={() => {
                                                                    if (login) {
                                                                        open_add_applic(index, slide_index)
                                                                    } else {
                                                                        window.location.href = "/authorization"
                                                                    }
                                                                }}>
                                                            Создать
                                                        </button>
                                                    </div>
                                                </SwiperSlide>
                                            )) : null}
                                            {item["sub_categories"].length > 3 && (
                                                <>
                                                    {windowWidth > 780 ? (
                                                        <>
                                                            <div className="swiper-button-prev">
                                                                <img src="/new-imgs/Arrow13.svg" alt="Prev"
                                                                     className="swiper-img"/>
                                                            </div>
                                                            <div className="swiper-button-next">
                                                                <img src="/new-imgs/Arrow14.svg" alt="Next"
                                                                     className="swiper-img-last"/>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <div className="swiper-buttons">
                                                            <div className="swiper-button-prev">
                                                                <img src="/new-imgs/Arrow13.svg" alt="Prev"
                                                                     className="swiper-img"/>
                                                            </div>
                                                            <div className="swiper-button-next">
                                                                <img src="/new-imgs/Arrow14.svg" alt="Next"
                                                                     className="swiper-img-last"/>
                                                            </div>
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </Swiper>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
            <section className="window-closer-applic" onClick={() => open_add_applic()}></section>
            <div className="add-applic">
                {selectedCategory && (
                    <AddBlock type={"add"} applicationId={null} descriptionEdit={null}
                              selectedSubCategory={selectedSubCategory}
                              listSubCategories={selectedCategory.map(subCategory => subCategory.name)}
                              selectedCity={selectedCity} categoryName={categoryName}/>
                )}
            </div>
        </>
    )
}

export default HomePageForClient