import "./TariffBlock.css"
import open_tariff_block from "./open_tariff_block";
import axios from "axios";
import API_URL from "../../index";
import React, {useEffect, useState} from "react";

const TariffBlock = ({category, city}) => {
    const buy_category = async () => {
        if (category) {
            await axios.post(`${API_URL}/payment/buy-category?category=${category}`
            ).then(response => {
                if (response.status === 200) {
                    window.open(response.data, '_blank', 'noopener,noreferrer');
                }
            })
                .catch((error) => {
                    console.log(error)
                })
        }
    }

    return (
        <>
            <div className="tariff-block__column">
                <button className="tariff-block__cansel" onClick={open_tariff_block}>×</button>
                <h2>Годовая подписка Услуги 24/7</h2>
                <h3>Категория: {category}</h3>
                <h3>Город: {city}</h3>
                <span>В рамках этой подписки вам будут доступны все объявления, размещенные в выбранной категории,
                        включая все её подкатегории.
                        <br/>
                        <br/>
                        <b>Условия подписки:</b>
                        <br/>
                        <br/>
                        Подписка оформляется на 12 месяцев и только на тот город, который вы выбрали.
                        <br/>
                        <br/>
                        Автоматическое продление подписки отсутствует. По окончании срока действия подписки вам будет
                        предложено продлить её вручную.
                        <br/>
                        <br/>
                        В рамках подписки вы будете получать уведомления о новых объявлениях в выбранной категории
                        через Telegram.
                        <br/>
                        <br/>
                        <b>Пожалуйста, убедитесь в правильности выбора категории и города перед переходом к оплате.</b>
                    </span>
                <button className="tariff-block__pay" onClick={buy_category}>Перейти к оплате</button>
            </div>
        </>
    )
}

export default TariffBlock