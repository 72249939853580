import "../PrivacyPolicy/PrivacyPolicy.css"
import {useEffect, useState} from "react";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import {Link} from "react-router-dom";

const ListingPolicy = () => {
    const [scroll, setScroll] = useState(0)
    const handleScroll = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({behavior: 'smooth'});
        }
        // Удаляем якорь из URL
        window.history.replaceState(null, null, ' ');
    };

    const scrollToTop = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    };


    useEffect(() => {
        const handleScroll = () => {
            setScroll(window.scrollY);
        };

        // Добавляем обработчик события скролла
        window.addEventListener('scroll', handleScroll);

        // Убираем обработчик при размонтировании компонента
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        scrollToTop();
    }, []);

    return (
        <>
            <Header/>
            <div className="privacy-policy">
                <div className="container">
                    <h2 className="privacy-policy__title">Требования к размещению и содержанию объявлений</h2>
                    <div className="privacy-policy__column">
                        <div className="privacy-policy__content">
                            <h2 className="privacy-policy__content-title">Содержание</h2>
                            <ul className="privacy-policy__content-list">
                                <li className="privacy-policy__content-item">
                                    <Link to="#" onClick={() => handleScroll("section1")}
                                          className="privacy-policy__content-link">Объявление должно быть
                                        законным</Link>
                                </li>
                                <li className="privacy-policy__content-item">
                                    <Link to="#" onClick={() => handleScroll("section2")}
                                          className="privacy-policy__content-link">Информация в объявлении должна быть
                                        достоверной и полной</Link>
                                </li>
                                <li className="privacy-policy__content-item">
                                    <Link to="#" onClick={() => handleScroll("section3")}
                                          className="privacy-policy__content-link">Объявление должно соответствовать
                                        категориям на Услуги 24/7</Link></li>
                                <li className="privacy-policy__content-item">
                                    <Link to="#" onClick={() => handleScroll("section4")}
                                          className="privacy-policy__content-link">Предложение должно быть конкретным,
                                        существующим и актуальным</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="privacy-policy__column" id="section1">
                        <h2 className="privacy-policy__content-title">1. Объявление должно быть законным</h2>
                        <span className="privacy-policy__content-text">
                            Не размещайте в объявлениях предложения, условия или информацию, нарушающие применимое
                            законодательство или права третьих лиц, либо способствующие таким нарушениям. Примеры
                            включают требование о передаче паспорта в залог.
                            <br/>
                            <br/>
                            Вы вправе размещать объявления только о тех услугах и предложениях (далее – «<b>услуга</b>»),
                            в отношении которых у вас есть все необходимые права, лицензии, регистрации, разрешения,
                            сертификаты и т.п.
                            <br/>
                            <br/>
                            Перед размещением объявления вы обязаны убедиться в законности всех его элементов, включая
                            фотографии, видео и ссылки на сторонние сайты, когда они допустимы. За любые нарушения
                            законодательства или прав третьих лиц вы будете нести ответственность самостоятельно.
                        </span>
                    </div>
                    <div className="privacy-policy__column" id="section2">
                        <h2 className="privacy-policy__content-title">2. Информация в объявлении должна быть достоверной
                            и полной</h2>
                        <span className="privacy-policy__content-text">
                            Мы рассматриваем размещение любой информации, которая может ввести в заблуждение
                            относительно вашей услуги или условий вашего предложения, как грубое нарушение правил
                            платформы Услуги 24/7.
                            <br/>
                            <br/>
                            Это также означает, что:
                            <br/>
                            <br/>
                            — все параметры объявления (название, описание, категория, фотографии и т.п.) должны
                            не противоречить друг другу и соответствовать реальным условиям вашего предложения;
                            <br/>
                            <br/>
                            — при размещении объявления должен быть указан актуальный номер телефона, по которому с вами
                            можно связаться.
                            <br/>
                            <br/>
                            Если ваше предложение должно в силу закона содержать какие-либо сведения, вы обязаны их
                            указать.
                            <br/>
                            <br/>
                            Вы самостоятельно несете всю ответственность за распространение объявления, в котором
                            отсутствуют обязательные сведения или указана недостоверная информация.
                        </span>
                    </div>
                    <div className="privacy-policy__column" id="section3">
                        <h2 className="privacy-policy__content-title">3. Объявление должно соответствовать категориям на
                            Услуги 24/7</h2>
                        <span className="privacy-policy__content-text">
                            азмещайте объявления только о тех услугах, для которых на Услуги 24/7 предусмотрена
                            соответствующая тематическая категория. Не размещайте объявления о принятии в дар, заказе
                            услуг, сборе материальной помощи, знакомствах, находке, потере, покупке или поиске.
                            <br/>
                            <br/>
                            Объявления могут быть размещены в рамках одной категории с использованием только одного
                            профиля (в том числе, если вы действуете в рамках одной компании или совместного бизнеса,
                            семьи или в интересах ваших знакомых).
                            <br/>
                            <br/>
                            Запрещается размещать несколько услуг в одном объявлении, за исключением случаев, если они
                            составляют комплект или соответствуют одной категории.
                        </span>
                    </div>
                    <div className="privacy-policy__column" id="section4">
                        <h2 className="privacy-policy__content-title">4. Предложение должно быть конкретным,
                            существующим и актуальным</h2>
                        <span className="privacy-policy__content-text">
                            Размещайте объявления только о конкретных существующих услугах, которые имеют
                            потребительскую ценность. Например, на Услуги 24/7 нельзя размещать предложения, такие как
                            аренда объекта на другой планете.
                            <br/>
                            <br/>
                            Поддерживайте актуальную информацию об услуге в профиле на Услуги 24/7. Объявление может
                            быть снято с публикации, если иное не предусмотрено правилами или в интерфейсе Услуги 24/7.
                            <br/>
                            <br/>
                            При наличии технической возможности и только при включенной в профиле функции автоматической
                            активации, Услуги 24/7 автоматически активирует объявления после того, как истек очередной
                            максимальный срок их размещения. Если у вас нет потребности в автоматической активации
                            объявлений, отключите её в профиле или обратитесь к специалистам службы поддержки.
                            <br/>
                            <br/>
                            Помните, что размещение объявления может повлечь для вас обязательство перед потенциальным
                            исполнителем заключить и исполнить сделку в соответствии с гражданским законодательством.
                            <br/>
                            <br/>
                            Не размещайте в объявлениях информацию общего рекламного характера без конкретного
                            предложения.
                        </span>
                    </div>
                </div>
            </div>
            {scroll > 0 && (
                <button className="btn-up" onClick={scrollToTop}>⇪</button>
            )}
            <Footer/>
        </>
    )
}

export default ListingPolicy