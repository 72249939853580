import "./RegistrationFirstPage.css"
import {useState} from "react";
import RegistrationLastPage from "../RegistrationLastPage/RegistrationLastPage";
import axios from "axios";
import API_URL from "../../../index";
import {Link} from "react-router-dom";

const RegistrationFirstPage = ({options}) => {
    const [state, setState] = useState(1);
    const [type, setType] = useState('');
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [showOptions, setShowOptions] = useState(false);
    // const [onFocus, setOnFocus] = useState(false)

    const [name, setName] = useState("");
    const [inputValue, setInputValue] = useState('');
    const [phone, setPhone] = useState("");

    const [tgURL, setTgURL] = useState('')
    const [code, setCode] = useState('')
    const [agreed, setAgreed] = useState(false);

    const handleCheckboxChange = (event) => {
        setAgreed(event.target.checked);
    }

    const handleNameChange = (event) => {
        const value = event.target.value;
        // Разрешаем ввод только букв (как латиницы, так и кириллицы)
        const sanitizedValue = value.replace(/[^a-zA-Zа-яА-ЯёЁ\s]/g, "");
        setName(sanitizedValue);
    };

    const handleChange = (e) => {
        const value = e.target.value;
        setInputValue(value);

        // Фильтрация списка опций по введённому значению
        const filtered = options.filter(option =>
            option.toLowerCase().includes(value.toLowerCase())
        );

        // Устанавливаем отфильтрованные опции и показываем список
        setFilteredOptions(filtered);
        setShowOptions(true);
    };

    const handleOptionClick = (option) => {
        setInputValue(option);
        if (inputValue > 0) {
            setShowOptions(false);
        }
    };

    const handler_phone_data = (event) => {
        event.preventDefault();
    };

    const handleInputChange = (event) => {
        const value = event.target.value;
        // Удаляем все нецифровые символы
        const sanitizedValue = value.replace(/[^0-9]/g, "");
        setPhone(sanitizedValue);
        handler_phone_data(event);
    };

    const formatPhoneNumber = (phone) => {
        // Удаляем все символы, кроме цифр
        const cleanedPhone = phone.replace(/\D/g, '');

        // Приводим номер к формату +7, если он начинается с 7 или 8
        if (cleanedPhone.startsWith('7') || cleanedPhone.startsWith('8')) {
            return `+7${cleanedPhone.slice(1)}`;
        }

        // Если номер уже в правильном формате или не требует изменений, возвращаем его как есть
        return phone;
    };

    const register = async () => {
        // Приводим номер телефона к корректному формату
        const normPhone = formatPhoneNumber(phone);

        // Проверяем, что все необходимые поля заполнены
        if (name && inputValue && normPhone && agreed) {
            await axios.post(`${API_URL}/user/register`, {
                "name": name,
                "city": inputValue,
                "telephone_number": normPhone  // Используем отформатированный номер телефона
            }).then(response => {
                if (response.status === 200 || 201) {
                    setState(2);
                    setTgURL(response.data.url);
                    setCode(response.data.code);
                }
            })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    return (
        <>
            {state === 1 ? (
                <div className="authorization-first">
                    <h2 className="authorization-first__title">Регистрация</h2>
                    <div className="registration-first__input-container">
                        <input
                            type="text"
                            placeholder="Имя"
                            value={name}
                            onChange={handleNameChange}
                            className="dropdown-input"
                        />
                    </div>
                    <div className="registration-first__input-container">
                        <div className="dropdown-input-container">
                            {showOptions && filteredOptions.length > 0 ? (
                                <div className="options-container">
                                    {filteredOptions.map((option, index) => (
                                        <div
                                            key={index}
                                            className="option"
                                            onClick={() => handleOptionClick(option)}
                                        >
                                            {option}
                                        </div>
                                    ))}
                                </div>
                            ) : (showOptions && inputValue.length > 0) && (
                                <div className="options-container">
                                    <div className="option">Город не найден</div>
                                </div>
                            )}
                            <input
                                type="text"
                                placeholder="Город"
                                className="dropdown-input"
                                value={inputValue}
                                onChange={handleChange}
                                onFocus={() =>
                                    setShowOptions(true)
                                }
                                onBlur={() =>
                                    setTimeout(() => setShowOptions(false), 100)
                                }
                            />
                        </div>
                    </div>
                    <div className="registration-first__input-container">
                        <input
                            onChange={handleInputChange}
                            // onFocus={() => setOnFocus(true)}  // Устанавливаем фокус
                            // onBlur={() => setOnFocus(false)}  // Снимаем фокус, если это необходимо
                            type="tel"
                            inputMode="numeric"
                            placeholder="Телефон"
                            pattern="[0-9]*"
                            // style={{
                            //     color: phone.length < 11 && !onFocus ? "red" : phone.length === 0 || !onFocus ? "black" : phone.length < 11 ? "red" : "green"
                            // }}  // Корректный тернарный оператор
                            maxLength={11}
                            value={phone}
                            className="dropdown-input"
                        />
                    </div>
                    <label className="custom-checkbox">
                        <input type="checkbox" checked={agreed}
                               onChange={handleCheckboxChange} required/>
                        <span className="checkmark"></span>
                        <span className="custom-checkbox-text">
                            Я прочитал(а) и соглашаюсь с <Link className="checkbox-link" to={"/user-agreement"}
                                                               target="_blank">Пользовательским соглашением</Link>
                        </span>
                    </label>
                    <button className="confirmation-btn-reg" onClick={() => {
                        register();
                        setType('tg');
                    }}>Подтвердить через Telegram
                    </button>
                    {/*<button className="confirmation-btn" onClick={() => {*/}
                    {/*    setState(2);*/}
                    {/*    setType('phone')*/}
                    {/*}}>Подтвердить через входящий звонок*/}
                    {/*</button>*/}
                </div>
            ) : (
                <div className="authorization-first__and-back">
                    <RegistrationLastPage type={type} tgURL={tgURL} phone={formatPhoneNumber(phone)} code={code}/>
                    <button className="back-btn" onClick={() => setState(1)}>
                        <img src="/new-imgs/Arrow-back.svg" alt="Back" className="back-btn__img"/>Назад
                    </button>
                </div>
            )}
        </>
    )
}

export default RegistrationFirstPage