import "./PrivacyPolicy.css"
import {Link} from "react-router-dom";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import {useEffect, useState} from "react";

const PrivacyPolicy = () => {
    const [scroll, setScroll] = useState(0)
    const handleScroll = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({behavior: 'smooth'});
        }
        // Удаляем якорь из URL
        window.history.replaceState(null, null, ' ');
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };


    useEffect(() => {
        const handleScroll = () => {
            setScroll(window.scrollY);
        };

        // Добавляем обработчик события скролла
        window.addEventListener('scroll', handleScroll);

        // Убираем обработчик при размонтировании компонента
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        scrollToTop();
    }, []);

    return (
        <>
            <Header/>
            <div className="privacy-policy">
                <div className="container">
                    <h2 className="privacy-policy__title">Политика конфиденциальности</h2>
                    <div className="privacy-policy__column">
                        <div className="privacy-policy__content">
                            <h2 className="privacy-policy__content-title">Содержание</h2>
                            <ul className="privacy-policy__content-list">
                                <li className="privacy-policy__content-item">
                                    <Link to="#" onClick={() => handleScroll("section1")}
                                          className="privacy-policy__content-link">Общие положения</Link>
                                </li>
                                <li className="privacy-policy__content-item">
                                    <Link to="#" onClick={() => handleScroll("section2")}
                                          className="privacy-policy__content-link">Определения</Link>
                                </li>
                                <li className="privacy-policy__content-item">
                                    <Link to="#" onClick={() => handleScroll("section3")}
                                          className="privacy-policy__content-link">Цели обработки
                                        персональных данных</Link></li>
                                <li className="privacy-policy__content-item">
                                    <Link to="#" onClick={() => handleScroll("section4")}
                                          className="privacy-policy__content-link">Правовые основания
                                        обработки</Link></li>
                                <li className="privacy-policy__content-item">
                                    <Link to="#" onClick={() => handleScroll("section5")}
                                          className="privacy-policy__content-link">Размещение данных на
                                        Услуги 24/7</Link></li>
                                <li className="privacy-policy__content-item">
                                    <Link to="#" onClick={() => handleScroll("section6")}
                                          className="privacy-policy__content-link">Безопасность</Link>
                                </li>
                                <li className="privacy-policy__content-item">
                                    <Link to="#" onClick={() => handleScroll("section7")}
                                          className="privacy-policy__content-link">Хранение персональных
                                        данных</Link></li>
                                <li className="privacy-policy__content-item">
                                    <Link to="#" onClick={() => handleScroll("section8")}
                                          className="privacy-policy__content-link">Сроки обработки
                                        данных</Link></li>
                                <li className="privacy-policy__content-item">
                                    <Link to="#" onClick={() => handleScroll("section9")}
                                          className="privacy-policy__content-link">Наши реквизиты</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="privacy-policy__column" id="section1">
                        <h2 className="privacy-policy__content-title">1. Общие положения</h2>
                        <span className="privacy-policy__content-text">
                            ИП Щербинин Н.В. («<b>компания</b>», или «<b>мы</b>») обрабатывает и защищает персональные
                            данные зарегистрированных и незарегистрированных пользователей Услуги 24/7 – всех, кто
                            обращается к нашему сайту и мобильному приложению, указанным на сайте («<b>пользователи</b>», или «<b>вы</b>»).
                            <br/>
                            <br/>
                            Отношения, связанные с обработкой компанией персональных данных, регулируются
                            законодательством Российской Федерации.
                            <br/>
                            <br/>
                            <b>Предоставляя нам персональные данные, вы соглашаетесь на их обработку в соответствии с
                                этой политикой.</b>
                        </span>
                    </div>
                    <div className="privacy-policy__column" id="section2">
                        <h2 className="privacy-policy__content-title">2. Определения</h2>
                        <span className="privacy-policy__content-text">
                            Персональные данные – любая информация, относящаяся к прямо или косвенно определённому, или
                            определяемому физическому лицу (субъекту персональных данных).
                            <br/>
                            <br/>
                            Обработка персональных данных – осуществление действий или совокупности действий в отношении
                            персональных данных, включая сбор, запись, систематизацию, накопление, хранение, уточнение,
                            обновление и изменение, извлечение, использование, предоставление, доступ, блокирование,
                            удаление и уничтожение – как с использованием, так и без использования средств
                            автоматизированной обработки данных.
                            <br/>
                            <br/>
                            Мы можем обрабатывать ваши данные перечисленными способами в целях, закрепленных в разделе 4
                            настоящей Политики.
                            <br/>
                            <br/>
                            Оператор персональных данных – государственный орган, муниципальный орган, юридическое или
                            физическое лицо, самостоятельно или совместно с другими лицами организующие и (или)
                            осуществляющие обработку персональных данных, а также определяющие цели обработки
                            персональных данных, состав персональных данных, подлежащих обработке, действия (операции),
                            совершаемые с персональными данными.
                            <br/>
                            <br/>
                            Компания является оператором по отношению к персональным данным, которые мы можем получить
                            в связи с использованием вами Услуги 24/7 (включая сайт и мобильное приложение).
                        </span>
                    </div>
                    <div className="privacy-policy__column" id="section3">
                        <h2 className="privacy-policy__content-title">3. Цели обработки персональных данных</h2>
                        <span className="privacy-policy__content-text">
                            Мы обрабатываем персональные данные пользователей Услуги 24/7 следующим образом:
                        </span>
                        <table>
                            <thead>
                            <tr>
                                <th>Цели обработки</th>
                                <th>Данные, которые можем обрабатывать (если применимо)</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <th>Предоставление вам возможности пользоваться Услуги 24/7 и услугами компании: создавать
                                    профиль, размещать объявления, оплачивать услуги и т.п., а также, с вашего согласия – предоставлять вам
                                    возможность пользоваться услугами наших партнёров
                                </th>
                                <th rowSpan="4">Имя, номер телефона, учетная
                                    запись социальной сети Telegram, город, сведения о платежах, иные сведения, связанные с использованием сервисов
                                    Услуги 24/7 или наших партнеров, технические данные и сведения,
                                    собираемые метрическими программами
                                </th>
                            </tr>
                            <tr>
                                <th>Обеспечение стабильной работы и безопасности Услуги 24/7, улучшение пользовательского
                                    опыта, качества услуг, сервисов и маркетинговых мероприятий, разработка новых
                                    сервисов и развитие функционала на сайте Услуги 24/7
                                </th>
                            </tr>
                            <tr>
                                <th>Предупреждение и пресечение нарушения законодательства, Условий использования Услуги 24/7
                                    и других наших правил и условий, в том числе защита пользователей от мошеннических и
                                    иных недобросовестных действий
                                </th>
                            </tr>
                            <tr>
                                <th>Выполнение обязанностей, предусмотренных законодательством (например, для
                                    бухгалтерского учёта, налоговой отчётности, ответов на запросы государственных
                                    органов)
                                </th>
                            </tr>
                            <tr>
                                <th>Предоставление ответов на ваши обращения</th>
                                <th>Имя, номер телефона, адрес</th>
                            </tr>
                            <tr>
                                <th>Направление маркетинговых сообщений об Услуги 24/7, Автотеке, Автохабе, Харабе и наших
                                    партнерах
                                </th>
                                <th>Имя, номер телефона, аккаунт Telegram</th>
                            </tr>
                            </tbody>
                        </table>
                        <span className="privacy-policy__content-text">
                            Если вы обращаетесь к нам как представитель государственного органа или СМИ, мы обрабатываем
                            данные, указанные в обращении (имя, номер телефона, город) в целях выполнения обязанностей, предусмотренных
                            законодательством, и ответа на ваше обращение.
                        <br/>
                        <br/>
                            Если вы обращаетесь к нам как правообладатель, мы обрабатываем данные, указанные в обращении
                            (имя, номер телефона, город) в целях выполнения обязанностей, предусмотренных
                            законодательством, и ответа на ваше обращение.
                        </span>
                    </div>
                    <div className="privacy-policy__column" id="section4">
                        <h2 className="privacy-policy__content-title">4. Правовые основания обработки</h2>
                        <span className="privacy-policy__content-text">
                            Мы обрабатываем ваши персональные данные:
                            <br/>
                            <br/>
                            — с вашего согласия.
                            <br/>
                            <br/>
                            Вы даёте согласие на обработку ваших персональных данных при регистрации и каждом случае
                            входа на Услуги 24/7, а также в процессе использования Услуги 24/7. Например, когда вы
                            выполняете инструкции в интерфейсах Услуги 24/7, нажимаете на специальные кнопки,
                            продолжаете звонок или совершаете иные действия. Вы даете согласие на 1 год с даты удаления
                            вашего профиля на Услуги 24/7 либо, если вы являетесь незарегистрированным
                            пользователем, - с даты достижения цели обработки персональных данных.
                            <br/>
                            <br/>
                            Другой срок может быть предусмотрен в отдельных случаях;
                            <br/>
                            <br/>
                            — для заключения, исполнения, изменения или прекращения договора – Условий использования
                            Услуги 24/7 и других наших условий и правил, а также ваших договоров с нашими партнёрами;
                            <br/>
                            <br/>
                            — для осуществления прав и законных интересов компании или третьих лиц, если при этом не
                            нарушаются ваши права и свободы;
                            <br/>
                            <br/>
                            — для выполнения обязанностей, возложенных на нас законодательством.
                        </span>
                    </div>
                    <div className="privacy-policy__column" id="section5">
                        <h2 className="privacy-policy__content-title">5. Размещение данных на Услуги 24/7</h2>
                        <span className="privacy-policy__content-text">
                            Когда вы публикуете на Услуги 24/7 объявления, отзывы или информацию в своём профиле,
                            персональные данные в составе такой информации становятся доступными неопределённому кругу
                            лиц. Вы раскрываете такие данные самостоятельно без предоставления нам как оператору
                            персональных данных отдельного согласия. Компания не распространяет ваши персональные
                            данные. Мы обрабатываем такие данные в целях исполнения договора с вами, заключенного по
                            вашей инициативе.
                            <br/>
                            <br/>
                            Цель, с которой пользователи размещают данные на Услуги 24/7 – установить контакт с
                            потенциальным исполнителем, который заинтересован в заключении сделки по объявлению.
                            Пользователи не вправе обрабатывать данные других пользователей для любых иных целей.
                            Это означает, что:
                            <br/>
                            <br/>
                            — вы не можете звонить пользователям, чтобы предлагать свои товары или услуги;
                            <br/>
                            <br/>
                            — вы не можете копировать данные пользователей, чтобы размещать их на других сервисах;
                            <br/>
                            <br/>
                            — вы не можете использовать информацию пользователей Услуги 24/7 в целях скоринга.
                        </span>
                    </div>
                    <div className="privacy-policy__column" id="section6">
                        <h2 className="privacy-policy__content-title">6. Безопасность</h2>
                        <span className="privacy-policy__content-text">
                            Ответственное отношение к персональным данным – стандарт работы компании.
                            <br/>
                            <br/>
                            Для защиты персональных данных мы:
                            <br/>
                            <br/>
                            — издали и опубликовали на сайте политику обработки данных. Утвердили локальные акты по
                            вопросам обработки и защиты персональных данных. Работники знакомятся с этими документами в
                            свой первый день в компании.
                            <br/>
                            <br/>
                            — назначили лицо, ответственное за организацию обработки персональных данных.
                            <br/>
                            <br/>
                            — регулярно проверяем процессы и документы компании на их соответствие закону.
                            <br/>
                            <br/>
                            — оцениваем риски и вред, который можем причинить, если нарушим закон о персональных данных.
                            С учетом проведенной оценки подбираем подходящие меры для соблюдения закона.
                            <br/>
                            <br/>
                            — предоставляем доступ к персональным данным только тем сотрудникам Компании, которым он
                            действительно необходим для выполнения обязанностей.
                            <br/>
                            <br/>
                            — применяем правовые, организационные и технические меры, чтобы обеспечить безопасность
                            персональных данных.
                            <br/>
                            <br/>
                            Принимая меры по защите персональных данных, мы основываемся на:
                            <br/>
                            <br/>
                            — требованиях законодательства;
                            <br/>
                            <br/>
                            — установленном уровне защищенности персональных данных;
                            <br/>
                            <br/>
                            — актуальных угрозах, определенных моделью угроз;
                            <br/>
                            <br/>
                            — базовом наборе мер защиты, установленном нормативными правовыми актами для
                            соответствующего уровня защищенности;
                            <br/>
                            <br/>
                            — риск-ориентированном подходе при выборе оптимальных мер;
                            <br/>
                            <br/>
                            — приоритете законных интересов пользователей.
                            <br/>
                            <br/>
                            Компания соответствует требованиям стандарта PCI DSS, что подтверждает полученный нами
                            сертификат. Ваши данные надёжно защищены.
                        </span>
                    </div>
                    <div className="privacy-policy__column" id="section7">
                        <h2 className="privacy-policy__content-title">7. Хранение персональных данных</h2>
                        <span className="privacy-policy__content-text">
                            Мы осуществляем запись, систематизацию, накопление, хранение, уточнение (обновление,
                            изменение), извлечение ваших персональных данных с использованием баз данных, находящихся на
                            территории России.
                            <br/>
                            <br/>
                            Для хранения данных мы используем центры обработки данных, находящиеся на территории г.
                            Омск.
                            <br/>
                            <br/>
                            Мы храним ваши данные в соответствии со сроками обработки данных, необходимыми для
                            достижения целей обработки, указанных в разделе 4 настоящей Политики.
                        </span>
                    </div>
                    <div className="privacy-policy__column" id="section8">
                        <h2 className="privacy-policy__content-title">8. Сроки обработки данных</h2>
                        <span className="privacy-policy__content-text">
                            Мы прекращаем обработку ваших персональных данных в сроки, установленные законом:
                            <br/>
                            <br/>
                            — по достижении целей обработки, указанных в разделе 4 настоящей Политики, либо при утрате
                            необходимости в их достижении (если нет других оснований для обработки, предусмотренных
                            законодательством).
                            <br/>
                            <br/>
                            Например, для выполнения возложенных на нас обязанностей, предупреждения и пресечения
                            нарушений законов, наших правил, защиты пользователей от мошеннических и иных
                            недобросовестных действий, а также для предоставления ответов на обращения, мы должны
                            обрабатывать данные в течение сроков, определенных в соответствии с законодательством
                            (процессуальным, налоговым, гражданским, о бухгалтерском учете, пр.).
                            <br/>
                            <br/>
                            Такие цели, как предоставление возможности пользоваться сервисами Услуги 24/7, обеспечение
                            стабильной работы и безопасности сервисов, улучшение пользовательского опыта, качества
                            сервисов, услуг и маркетинговых мероприятий, направление маркетинговых сообщений, будут
                            достигнуты при прекращении обязательств, вытекающих из договоров с нами.
                            <br/>
                            <br/>
                            — по истечении срока согласия или при отзыве согласия (если нет других оснований для
                            обработки, предусмотренных законодательством). Например, в случае обработки для организации
                            участия в мероприятиях, исследованиях и опросах.
                            <br/>
                            <br/>
                            Также мы прекратим обработку ваших данных:
                            <br/>
                            <br/>
                            — при выявлении неправомерной обработки, если обеспечить правомерность невозможно;
                            <br/>
                            <br/>
                            — при ликвидации компании.
                            <br/>
                            <br/>
                            По истечении установленных сроков, мы автоматически удаляем данные из информационных систем.
                            Если данные обрабатываются без использования средств автоматической обработки (например,
                            бумажные обращения) – уничтожаем такие материальные носители.
                        </span>
                    </div>
                    <div className="privacy-policy__column" id="section9">
                        <h2 className="privacy-policy__content-title">9. Наши реквизиты</h2>
                        <span className="privacy-policy__content-text">
                            <b>Реквизиты компании:</b>
                            <br/>
                            <br/>
                            ИП Щербинин Н.В.
                            <br/>
                            <br/>
                            ИНН: 550403079841
                            <br/>
                            <br/>
                            <b>Банковские реквизиты:</b>
                            <br/>
                            <br/>
                            Название банка: ООО "Банк Точка"
                            <br/>
                            <br/>
                            БИК: 044525104
                            <br/>
                            <br/>
                            Корреспондентский счёт: 30101810745374525104
                            <br/>
                            <br/>
                            Расчётный счёт: 40802810301500416381
                        </span>
                    </div>
                </div>
                {scroll > 0 && (
                    <button className="btn-up" onClick={scrollToTop}>⇪</button>
                )}
            </div>
            <Footer/>
        </>
    )
}

export default PrivacyPolicy