import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import "./AccountPage.css"
import axios from "axios";
import API_URL from "../../index";
import React, {useEffect, useState} from "react";

const AccountPage = () => {
    const windowWidth = window.innerWidth;
    const [userData, setUserData] = useState()
    const [tariffData, setTariffData] = useState(Array)
    const [city, setCity] = useState("")

    const me = async () => {
        if (window.location.pathname !== "/authorization" && window.location.pathname !== "/registration") {
            await axios.get(`${API_URL}/user/me`, {withCredentials: true})
                .then(response => {
                    if (response.status === 200) {
                        setUserData(response.data)
                        console.log(response.data);
                        console.log(response.data["city"]);
                        setCity(response.data["city"]["name"])
                    }
                })
                .catch((error) => {
                    console.log(error)
                    // window.location.href = "/authorization"
                })
        }
    }

    const formatDate = (dateString) => {
        // Убираем микросекунды (после точки) из строки даты
        const normalizedDateString = dateString.split('.')[0];

        // Создаем объект даты из строки
        const date = new Date(normalizedDateString);

        // Проверяем, является ли дата валидной
        if (isNaN(date)) {
            console.error('Некорректная дата:', dateString);
            return 'Неверная дата'; // Можно вернуть сообщение об ошибке или другое значение
        }

        // Получаем день, месяц и год
        const day = String(date.getDate()).padStart(2, '0'); // День с ведущим нулем
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Месяц с ведущим нулем (нумерация месяцев с 0)
        const year = date.getFullYear(); // Год

        // Возвращаем дату в нужном формате
        return `${day}.${month}.${year}`;
    };

    const get_categories = async () => {
        if (city.length > 0) {
            await axios.get(`${API_URL}/application/get-categories?offset=0&city_name=${city}`, {withCredentials: true})
                .then(async response => {
                    if (response.status === 200) {
                        const categories = response.data;
                        const options = categories.map(category => category["name"])

                        const result = await check_category(options);
                        setTariffData(result)
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }

    const check_category = async (categories) => {
        // Используем Promise.all для параллельного выполнения запросов для каждой категории
        const promises = categories.map(async (category) => {
            try {
                const response = await axios.get(`${API_URL}/payment/check-category?category=${category}`, {withCredentials: true});

                if (response.status === 200 && response.data !== null) {
                    // Формируем объект с нужными данными
                    return {
                        "category_name": category,
                        "date_of_status_change": response.data.date_of_status_change,
                        "expired_at": response.data.expired_at,
                        "status": response.data.status,
                        "date_of_creation": response.data.date_of_creation,
                    };
                }
            } catch (error) {
                console.log(error);
            }
            return null; // Возвращаем null, если запрос не удался или данных нет
        });

        // Ожидаем выполнения всех запросов и фильтруем результат, убирая null значения
        return (await Promise.all(promises)).filter(result => result !== null);

    }

    // {
    //     "category_id": 1,
    //     "date_of_status_change": "2024-08-27T16:57:10.857447",
    //     "expired_at": "2025-08-27T16:57:10.857447",
    //     "status": "active",
    //     "date_of_creation": "2024-08-27T16:56:59.348936",
    // }

    const delete_me = async () => {

        await axios.delete(`${API_URL}/user/delete-me`, {withCredentials: true})
            .then(response => {
                if (response.status === 200) {
                    window.location.href = "/"
                }
            })
            .catch((error) => {
                console.log(error)
                // window.location.href = "/authorization"
            })
    }

    const confirmation_archiving = async (id) => {
        const section = document.querySelector(".confirmation-archiving")
        if (section.classList.contains("open")) {
            section.classList.remove("open")
        } else {
            section.classList.add("open")
        }
    }


    useEffect(() => {
        me()
        get_categories()
    }, [])

    useEffect(() => {
        get_categories()
    }, [city])

    return (
        <>
            <Header/>
            <div className="account">
                <div className="container account-container">
                    <div className="account__column">
                        {/*{userData && (*/}
                        <>
                            <h2 className="account__title">Аккаунт</h2>
                            <div className="account__info-container">
                                <div className="account__name">
                                    <div className="account__name-container">
                                        <img src="/new-imgs/account-user.svg" alt="Name"
                                             className="account__name-img"/>
                                        <h4 className="account__placeholder">Имя:</h4>
                                    </div>
                                    <h3 className="account__info">{userData ? userData["name"] : "undefined"}</h3>
                                </div>
                                <div className="account__name">
                                    <div className="account__name-container">
                                        <img style={{width: "18px"}} src="/new-imgs/account-phone.svg" alt="Phone"
                                             className="account__name-img"/>
                                        <h4 className="account__placeholder">Телефон:</h4>
                                    </div>
                                    <h3 className="account__info">
                                        {/*8 (999) 99-99-99*/}
                                        {userData ? userData["telephone_number"] : "undefined"}</h3>
                                </div>
                                <div className="account__name">
                                    <div className="account__name-container">
                                        <img src="/new-imgs/account-city.svg" alt="Account"
                                             className="account__name-img"/>
                                        <h4 className="account__placeholder">Город:</h4>
                                    </div>
                                    <h3 className="account__info">{userData ? userData["city"]["name"] : "undefined"}</h3>
                                </div>
                            </div>
                            <div className="account__tariff">
                                <h2 className="account__tariff-title">Подписка</h2>
                                <h5 className="account__tariff-text">Оплаченная подписка позволяет просматривать
                                    все
                                    актуальные объявления на платформе</h5>
                                {tariffData && tariffData.length > 0 ? (
                                    <ul className="account__tariff-list">
                                        {tariffData.map((item, index) => (
                                            <li key={index} className="account__tariff-item">
                                                <h4 className="account__tariff-category">Категория
                                                    «{item["category_name"]}»</h4>
                                                {item["status"] === "active" ? (
                                                    <h3 className="account__tariff-date gr"
                                                        style={{"color": "#26BD00"}}>
                                                        {`Оплачено до ${formatDate(item["expired_at"])}`}
                                                    </h3>
                                                ) : item["status"] === "pending" ? (
                                                    <h3 className="account__tariff-date yel">Ожидает оплаты</h3>
                                                ) : item["status"] === "archive" && (
                                                    <h3 className="account__tariff-date rd">Завершена</h3>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    <h3 className="account__tariff-date rd">Ни одна
                                        категория не оплачена</h3>
                                )}
                            </div>
                            <div className="account-btn-container">
                                <button className="block-reg-or-auth__btn account-btn"
                                        onClick={confirmation_archiving}>
                                    Удалить аккаунт
                                </button>
                            </div>
                        </>
                        {/*)}*/}
                    </div>
                    <div className="confirmation-archiving open">
                        <div className="confirmation-archiving__column">
                            <div className="confirmation-archiving__btn-container">
                                <button className="confirmation-archiving__btn-close" onClick={confirmation_archiving}>
                                    <img src="/new-imgs/close.svg" alt="Close"
                                         className="confirmation-archiving__btn-img"/>
                                </button>
                            </div>
                            <span
                                className="confirmation-archiving__text">Вы уверены, что хотите удалить аккаунт?</span>
                            <div className="confirmation-archiving__btn-container">
                                <button onClick={confirmation_archiving}
                                        className="confirmation-archiving__btn">Нет
                                </button>
                                <button onClick={delete_me} className="confirmation-archiving__btn">Да
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default AccountPage