import React, {useEffect, useState} from "react";
import "./Footer.css"
import {Link} from "react-router-dom";
import axios from "axios";
import API_URL from "../../index";

const Footer = () => {
    const [login, setLogin] = useState(false)

    const me = async () => {
        if (window.location.pathname !== "/authorization" && window.location.pathname !== "/registration") {
            await axios.get(`${API_URL}/user/me`, {withCredentials: true})
                .then(response => {
                    if (response.status === 200) {
                        setLogin(true)
                    }
                })
                .catch((error) => {
                    if (error.response && error.response.status === 403) {
                        setLogin(false)
                    } else {
                        console.log(error)
                    }
                })
        }
    }

    useEffect(() => {
        me()
    }, [])

    return (
        <>
            <div className="footer">
                <div className="container footer-container">
                    <div className="footer__column">
                        {/*style={window.location.pathname === "/authorization" || window.location.pathname === "/registration" ? {justifyContent: "center"} : {justifyContent: "space-between"}}>*/}
                        <div className="footer__title">
                            <img src="/new-imgs/title.svg" alt="Logo" className="footer__logo"/>
                            <span className="footer_logo_info">© 2024 все права защищены</span>
                        </div>
                        <div className="footer_btns">
                            <button className="footer_btn">
                                <img src="/new-imgs/google_play.svg" alt="Google Play" className="footer_btn_img"/>
                            </button>
                            <button className="footer_btn">
                                <img src="/new-imgs/rustore.svg" alt="RuStore" className="footer_btn_img"/>
                            </button>
                            {window.innerWidth <= 580 && (
                                <Link to="#" className="footer__link-item footer__link-item_tg">
                                    <img src="/new-imgs/footer-tg.svg" alt="Telegram" className="footer__link-tg-logo"/>
                                    ТехПоддержка
                                </Link>
                            )}
                        </div>
                        <div className="footer__company">
                            <h3 className="footer__title-h3">Компания</h3>
                            {/*<Link to="#" className="footer__link-item">О нас</Link>*/}
                            <Link to="/services-agreement" className="footer__link-item">Оферта на оказание
                                услуг</Link>
                            <Link to="/privacy-policy" className="footer__link-item">Политика
                                конфиденциальности</Link>
                        </div>
                        <div className="footer__help">
                            <h3 className="footer__title-h3">Помощь</h3>
                            {window.location.pathname !== "/authorization" && window.location.pathname !== "/registration" && (
                                <Link to={login ? "/account" : "/authorization"} className="footer__link-item">Мой
                                    аккаунт</Link>
                            )}
                            <Link to="/rules" className="footer__link-item">Правила Услуги 24/7</Link>
                        </div>
                        {window.innerWidth > 580 && (
                            <Link to="#" className="footer__link-item footer__link-item_tg">
                                <img src="/new-imgs/footer-tg.svg" alt="Telegram" className="footer__link-tg-logo"/>
                                ТехПоддержка
                            </Link>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer