import React, {useEffect, useState} from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import HomePageForClient from "./HomePageForClient/HomePageForClient";
import HomePageForIsp from "./HomePageForIsp/HomePageForIsp";
import axios from "axios";
import API_URL from "../../index";

const HomePage = () => {
    const [role, setRole] = useState(() => {
        const savedRole = localStorage.getItem('role');
        return savedRole || 'client';
    });
    const [categories, setCategories] = useState([])
    const [selectedCity, setSelectedCity] = useState("")
    const [data, setData] = useState()
    const [login, setLogin] = useState(false)
    const [changedCity, setChangedCity] = useState("")

    useEffect(() => {
        localStorage.setItem('role', role);
    }, [role]);

    const handleRoleChange = () => {
        setRole(prevRole => (prevRole === 'client' ? 'isp' : 'client'));
    };

    const windowWidth = window.innerWidth;

    const get_categories = async (city) => {
        await axios.get(`${API_URL}/application/get-categories?city_name=${city}&offset=0`, {withCredentials: true})
            .then(response => {
                if (response.status === 200) {
                    const data = response.data;
                    setData(data);
                    const options = data
                        .filter(category => category["sub_categories"].length > 0)  // Сначала фильтруем
                        .map(category => category["name"]);
                    setCategories(options);
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const me = async () => {
        if (window.location.pathname !== "/authorization" && window.location.pathname !== "/registration") {
            await axios.get(`${API_URL}/user/me`, {withCredentials: true})
                .then(response => {
                    if (response.status === 200) {
                        setLogin(true);
                        setSelectedCity(response.data["city"]["name"]);
                        get_categories(response.data["city"]["name"]);
                    } else {
                        setLogin(false);
                    }
                })
                .catch((error) => {
                    setLogin(false)
                    setSelectedCity("Москва");
                    get_categories("Москва");
                })
        }
    }

    useEffect(() => {
        me();
    }, [])

    useEffect(() => {
        get_categories(changedCity.length > 0 ? changedCity : "Москва")
    }, [changedCity])

    return (
        <>
            <Header role={role} onRoleChange={handleRoleChange} setChangedCity={setChangedCity}/>
            {role === 'client' ? (
                <HomePageForClient windowWidth={windowWidth} categories={data} selectedCity={selectedCity} login={login}/>
            ) : (
                <HomePageForIsp windowWidth={windowWidth} data={data} categories={categories} login={login} city={selectedCity}/>
            )}
            <Footer/>
        </>
    )
}

export default HomePage