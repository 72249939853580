const open_tariff_block = () => {
    const section = document.querySelector(".tariff-block")
    const black = document.querySelector(".window-closer-tariff")
    if (section.classList.contains("open")) {
        section.classList.remove("open")
        black.classList.remove("open")
    } else {
        section.classList.add("open")
        black.classList.add("open")
    }
}

export default open_tariff_block