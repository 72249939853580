import {Link} from "react-router-dom";
import "../PrivacyPolicy/PrivacyPolicy.css"
import {useEffect, useState} from "react";
import Footer from "../../../components/Footer/Footer";
import Header from "../../../components/Header/Header";

const TermsOfUse = () => {

    const [scroll, setScroll] = useState(0)
    const handleScroll = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({behavior: 'smooth'});
        }
        // Удаляем якорь из URL
        window.history.replaceState(null, null, ' ');
    };

    const scrollToTop = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    };


    useEffect(() => {
        const handleScroll = () => {
            setScroll(window.scrollY);
        };

        // Добавляем обработчик события скролла
        window.addEventListener('scroll', handleScroll);

        // Убираем обработчик при размонтировании компонента
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        scrollToTop();
    }, []);

    return (
        <>
            <Header/>
            <div className="privacy-policy">
                <div className="container">
                    <h2 className="privacy-policy__title">Условия использования Услуги 24/7</h2>
                    <div className="privacy-policy__column">
                        <div className="privacy-policy__content">
                            <h2 className="privacy-policy__content-title">Содержание</h2>
                            <ul className="privacy-policy__content-list">
                                <li className="privacy-policy__content-item">
                                    <Link to="#" onClick={() => handleScroll("section1")}
                                          className="privacy-policy__content-link">Что такое Услуги 24/7</Link>
                                </li>
                                <li className="privacy-policy__content-item">
                                    <Link to="#" onClick={() => handleScroll("section2")}
                                          className="privacy-policy__content-link">Регистрация на Услуги 24/7</Link>
                                </li>
                                <li className="privacy-policy__content-item">
                                    <Link to="#" onClick={() => handleScroll("section3")}
                                          className="privacy-policy__content-link">Вход на Услуги 24/7</Link></li>
                                <li className="privacy-policy__content-item">
                                    <Link to="#" onClick={() => handleScroll("section4")}
                                          className="privacy-policy__content-link">Сведения о пользователях</Link></li>
                                <li className="privacy-policy__content-item">
                                    <Link to="#" onClick={() => handleScroll("section5")}
                                          className="privacy-policy__content-link">Пользовательский контент</Link></li>
                                <li className="privacy-policy__content-item">
                                    <Link to="#" onClick={() => handleScroll("section6")}
                                          className="privacy-policy__content-link">Защита интеллектуальных прав</Link></li>
                                <li className="privacy-policy__content-item">
                                    <Link to="#" onClick={() => handleScroll("section7")}
                                          className="privacy-policy__content-link">Лицензия на использование баз данных и программ</Link></li>
                                <li className="privacy-policy__content-item">
                                    <Link to="#" onClick={() => handleScroll("section8")}
                                          className="privacy-policy__content-link">Обязанности пользователей</Link>
                                </li>
                                <li className="privacy-policy__content-item">
                                    <Link to="#" onClick={() => handleScroll("section9")}
                                          className="privacy-policy__content-link">Нарушения и последствия</Link>
                                </li>
                                <li className="privacy-policy__content-item">
                                    <Link to="#" onClick={() => handleScroll("section10")}
                                          className="privacy-policy__content-link">Заключительные положения</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="privacy-policy__column" id="section1">
                        <h2 className="privacy-policy__content-title">1. Что такое Услуги 24/7</h2>
                        <span className="privacy-policy__content-text">
                            Услуги 24/7 — это интернет-сайт, доступный при наличии технической возможности по адресу
                            услуги24-7.рф (включая все уровни домена) через полную и мобильную версии и мобильное
                            приложение, представляющий собой совокупность содержащихся в информационной системе объектов
                            интеллектуальной собственности Компании и информации (пользовательского и административного
                            контента) («Услуги 24/7»).
                        <br/>
                        <br/>
                            Услуги 24/7 представляет собой электронный каталог объявлений о услугах, которые пользователи могут
                            предлагать и искать на Услуги 24/7 с целью заключения сделок.
                        <br/>
                        <br/>
                            Компания предоставляет авторизованным пользователям техническую возможность размещать информацию
                            на Услуги 24/7 в формате объявлений в представленных категориях услуг. Компания
                            предоставляет всем пользователям (независимо от авторизации) техническую возможность искать
                            и просматривать категории на Услуги 24/7 в целях, предусмотренных Условиями. Автоматические
                            алгоритмы Услуги 24/7 обрабатывают запросы, чтобы предоставлять релевантную информацию в
                            результатах поиска. При этом просматривать и создавать объявления будет возможно только
                            после регистрации/авторизации и оплате тарифа/объявления.
                        <br/>
                        <br/>
                            Компания не является участником, организатором сделки, покупателем, продавцом, работодателем,
                            посредником, агентом, представителем какого-либо пользователя, выгодоприобретателем или иным
                            заинтересованным лицом в отношении сделок между пользователями. Пользователи используют
                            информацию на Услуги 24/7, чтобы заключать сделки на свой риск, самостоятельно оценивают её
                            полноту, надежность, достоверность и соответствие действующему законодательству без прямого
                            или косвенного участия или контроля со стороны Компании.
                        </span>
                    </div>
                    <div className="privacy-policy__column" id="section2">
                        <h2 className="privacy-policy__content-title">2. Регистрация на Услуги 24/7</h2>
                        <span className="privacy-policy__content-text">
                            Вы можете зарегистрироваться на Услуги 24/7 с помощью номера мобильного телефона российских
                            операторов связи или через ваш профиль в Telegram, представленной в форме входа на
                            Услуги 24/7. После регистрации мы создадим ваш уникальный профиль. Одновременно на
                            Услуги 24/7 может быть только один профиль, привязанный к одному номеру телефона.
                            <br/>
                            <br/>
                            Регистрируясь на Услуги 24/7 и при каждом случае входа на Услуги 24/7, вы гарантируете, что
                            обладаете всеми правомочиями, необходимыми для заключения и исполнения Условий.
                        </span>
                    </div>
                    <div className="privacy-policy__column" id="section3">
                        <h2 className="privacy-policy__content-title">3. Вход на Услуги 24/7</h2>
                        <span className="privacy-policy__content-text">
                            Вы можете войти в свой профиль на Услуги 24/7 (авторизоваться) с помощью
                            социальной сети Telegram, связанной с вашим профилем.
                            <br/>
                            <br/>
                            Вы обязаны сохранять конфиденциальность своих данных для входа на Услуги 24/7. Если у вас
                            есть основания полагать, что кто-то получил к ним несанкционированный доступ, немедленно
                            сообщите нам об этом. Все действия, совершённые на Услуги 24/7 лицом,
                            авторизованным с помощью ваших данных для входа, будут считаться совершёнными вами.
                            Ответственность за такие действия будете нести вы.
                        </span>
                    </div>
                    <div className="privacy-policy__column" id="section4">
                        <h2 className="privacy-policy__content-title">4. Сведения о пользователях</h2>
                        <span className="privacy-policy__content-text">
                            Мы можем запросить у вас данные для подтверждения полномочий или проверки указанных вами на
                            Услуги 24/7 сведений.
                            <br/>
                            <br/>
                            Мы не можем гарантировать, что вся информация, которую пользователи указывают на Услуги 24/7,
                            соответствует действительности. Будьте осмотрительны при совершении сделок.
                            <br/>
                            <br/>
                            Мы можем предоставить вам возможность пройти проверку благонадёжности по вашей собственной
                            инициативе, а также в отдельных случаях при выявлении признаков нарушения правил или
                            недобросовестного поведения попросить вас сделать это.
                            <br/>
                            <br/>
                            Проверка благонадёжности необходима для исполнения договора с вами о предоставлении вам
                            возможности использования бесплатного функционала Услуги 24/7 и обеспечения безопасности
                            пользователей Услуги 24/7, поскольку мы стремимся предоставлять сервис, в рамках которого
                            пользователям будет проще доверять друг другу и заключать сделки.
                            <br/>
                            <br/>
                            Мы анализируем, как вы используете Услуги 24/7, чтобы делать Услуги 24/7 лучше, помогать вам
                            измерять эффективность наших сервисов и рекомендовать вам сервисы Услуги 24/7 и других
                            компаний группы.
                        </span>
                    </div>
                    <div className="privacy-policy__column" id="section5">
                        <h2 className="privacy-policy__content-title">5. Пользовательский контент</h2>
                        <span className="privacy-policy__content-text">
                            Пользовательский контент — текст и изображения, которые пользователи Услуги 24/7
                            размещают в объявлениях.
                            <br/>
                            <br/>
                            Вы являетесь обладателем всей информации, которую размещаете на Услуги 24/7. Вы
                            самостоятельно создаете, изменяете, размещаете в открытом доступе для неограниченного круга
                            лиц и удаляете контент (в том числе персональные данные) без нашего участия или
                            согласования. Компания не несет ответственности за пользовательский контент, размещаемый
                            на Услуги 24/7, и не выступает его распространителем.
                            <br/>
                            <br/>
                            Если в составе вашего публичного профиля или объявлений вы публикуете свои персональные
                            данные, чтобы пользователи могли связаться с вами или получить наиболее полную информацию
                            о вашем предложении, вы самостоятельно раскрываете их неопределённому кругу лиц. Компания
                            обрабатывает ваши персональные данные в целях исполнения договора с вами – Условий
                            использования Услуги 24/7.
                            <br/>
                            <br/>
                            Если вы размещаете на Услуги 24/7 персональные данные третьих лиц или иным образом передаете
                            их Компании, вы гарантируете нам, что при этом соблюдены все применимые требования
                            законодательства о персональных данных, включая, но не ограничиваясь, в части наличия
                            необходимых согласий субъектов или иных законных оснований на такие действия.
                            <br/>
                            <br/>
                            Вы обязаны самостоятельно обеспечить полное соответствие пользовательского контента
                            применимому законодательству, Условиям использования Услуги 24/7, правилам размещения
                            объявлений, требованиям к профилю и отзывам. Вы гарантируете, что ваш контент не нарушает
                            законные права и интересы третьих лиц, в том числе права на результаты интеллектуальной
                            деятельности.
                            <br/>
                            <br/>
                            Услуги 24/7 не обязан заниматься предварительной проверкой пользовательского контента.
                            Мы предпринимаем действия в отношении контента на основании обращений или решений
                            уполномоченных лиц в установленном законодательством Российской Федерации порядке.
                            <br/>
                            <br/>
                            Цель, с которой пользователи размещают информацию на Услуги 24/7 — установить контакт с
                            потенциальным исполнителем, который заинтересован в заключении сделки в отношении услуги в
                            объявлении. Размещение пользовательского контента допускается исключительно в информационных
                            целях.
                            <br/>
                            <br/>
                            Вы предоставляете Компании право использовать ваш контент и включенные в него объекты
                            интеллектуальной собственности на условиях неисключительной лицензии: бессрочно, без
                            предоставления вознаграждения, любым способом, для действия во всем мире, как с указанием,
                            так и без указания имени автора. Мы можем использовать ваш контент для исполнения Условий,
                            улучшения Услуги 24/7, в рекламных и образовательных целях, в коммерческих и некоммерческих
                            проектах. Мы вправе сохранять архивные копии пользовательского контента и не изымать из оборота
                            материалы, которые его содержат.
                        </span>
                    </div>
                    <div className="privacy-policy__column" id="section6">
                        <h2 className="privacy-policy__content-title">6. Защита интеллектуальных прав</h2>
                        <span className="privacy-policy__content-text">
                            Исключительные права на программы для ЭВМ, необходимые для использования Услуги 24/7, базы
                            данных, графическое оформление интерфейса (дизайна) и другие элементы сайта Услуги 24/7,
                            фирменное наименование, товарные знаки/логотипы и другие отличительные знаки Компании, в том
                            числе используемые в составе доменного имени услуги24-7.рф, принадлежат Компании.
                            <br/>
                            <br/>
                            Словесное обозначение “Услуги 24/7” охраняется как товарный знак, который принадлежит
                            Компании.
                            <br/>
                            <br/>
                            Компании принадлежат права на зарегистрированные и незарегистрированные базы данных, в том
                            числе, но не ограничиваясь: базу объявлений Услуги 24/7 (рег. №2019670010), базу объявлений
                            Услуги 24/7 о транспортных средствах (рег. №2019670012), базу объявлений Услуги 24/7 о
                            недвижимости (рег. №2019670013), базу резюме Услуги 24/7 (рег. № 2020670023), базу вакансий
                            Услуги 24/7 (рег. №2020670024) (вместе — «<b>базы данных</b>»). Базы данных включают в себя
                            как контент, размещенный в открытом доступе на Услуги 24/7, так и контент, который в
                            определенный момент не доступен пользователям для просмотра. Как изготовитель баз данных
                            Компания несет существенные финансовые, материальные, организационные и другие затраты на их
                            создание.
                            <br/>
                            <br/>
                            Мы вправе автоматически наносить на загружаемые вами фотографии логотипы / водяные знаки
                            Услуги 24/7, чтобы защитить материалы в наших базах данных от неправомерного копирования.
                        </span>
                    </div>
                    <div className="privacy-policy__column" id="section7">
                        <h2 className="privacy-policy__content-title">7. Лицензия на использование баз данных и программ</h2>
                        <span className="privacy-policy__content-text">
                            Мы предоставляем пользователям неисключительную лицензию на использование баз данных и
                            программ для ЭВМ, необходимых для использования Услуги 24/7, для целей, предусмотренных в
                            Условиях. Вознаграждение за лицензию не взимается.
                            <br/>
                            <br/>
                            Лицензия предоставляется с момента доступа к Услуги 24/7 и продолжает действовать, пока вы
                            используете Услуги 24/7. Поиск и просмотр категорий услуг доступен всем пользователям независимо
                            от регистрации и авторизации. Для того, чтобы получить доступ к другим функциональным
                            возможностям Услуги 24/7, нужно авторизоваться или авторизоваться и оплатить тариф.
                            <br/>
                            <br/>
                            Без согласия Компании запрещено использовать технические средства для взаимодействия с
                            сервисом в обход обычного порядка использования баз данных и программ для ЭВМ. В том числе
                            запрещено использовать автоматизированные скрипты для сбора информации на Услуги 24/7, а
                            также для автоматической регистрации профилей.
                            <br/>
                            <br/>
                            Без согласия Компании запрещено использовать базы данных и программы для ЭВМ в целях, не
                            предусмотренных Условиями. Это означает, что запрещено извлекать материалы из баз данных
                            и/или осуществлять их последующее использование в любой форме и любым способом, независимо
                            от их объема и согласования с третьими лицами, если это не связано непосредственно с
                            заключением сделки в отношении товара в объявлении. Например, запрещено использовать
                            материалы из баз данных Услуги 24/7 для наполнения баз данных третьих лиц. Также запрещено
                            использовать номера телефонов из баз данных Услуги 24/7 для звонков пользователям Услуги
                            24/7 с предложениями услуг или товаров. Такие действия нарушают не только исключительное
                            право Компании, но и интеллектуальные права пользователей, а также представляют собой
                            обработку персональных данных пользователей Услуги 24/7 без их согласия.
                        </span>
                    </div>
                    <div className="privacy-policy__column" id="section8">
                        <h2 className="privacy-policy__content-title">8. Обязанности пользователей</h2>
                        <span className="privacy-policy__content-text">
                            Взаимодействуя с Компанией и другими пользователями Услуги 24/7, а также в связи с
                            использованием Услуги 24/7 вы обязуетесь:
                            <br/>
                            <br/>
                            — строго выполнять все требования законодательства и действовать добросовестно;
                            <br/>
                            <br/>
                            — соблюдать Условия использования Услуги 24/7, правила размещения объявлений, требования
                            к профилю и другие правила Услуги 24/7 и указания Компании;
                            <br/>
                            <br/>
                            — не связываться с пользователями Услуги 24/7, если вы не потенциальный исполнитель и не
                            заинтересованы в заключении сделки по объявлению, не совершать действий, приводящих к спаму;
                            <br/>
                            <br/>
                            — самостоятельно нести ответственность перед третьими лицами, в том числе, если ваши деяния
                            приведут к нарушению прав и законных интересов третьих лиц;
                            <br/>
                            <br/>
                            — не копировать с использованием автоматических средств информацию на Услуги 24/7, включая
                            пользовательский контент;
                            <br/>
                            <br/>
                            — не совершать противоправных деяний, а также не использовать изображения и высказывания,
                            которые провоцируют жестокость, ненависть или неуважительное отношение, содержат угрозы,
                            оскорбления или сведения порочащего характера, оправдывают незаконные или недобросовестные
                            действия, подстрекают к их совершению, не соответствуют нравственным нормам или деловой
                            практике, в том числе не использовать нецензурную и грубую лексику;
                            <br/>
                            <br/>
                            — не использовать Услуги 24/7 или размещённую на Услуги 24/7 информацию в целях, не
                            предусмотренных Условиями;
                            <br/>
                            <br/>
                            — не загружать и не использовать на Услуги 24/7 вредоносные программы;
                            <br/>
                            <br/>
                            — не предпринимать действия, которые могут помешать нормальной работе Услуги 24/7.
                        </span>
                    </div>
                    <div className="privacy-policy__column" id="section9">
                        <h2 className="privacy-policy__content-title">9. Нарушения и последствия</h2>
                        <span className="privacy-policy__content-text">
                            Мы стремимся сделать Услуги 24/7 удобным и безопасным инструментом, поэтому сохраняем за
                            собой право проводить выборочную проверку выполнения пользователями Условий использования
                            Услуги 24/7 в любой момент и по своему усмотрению. Для этого мы можем использовать
                            автоматизированные методы.
                            <br/>
                            <br/>
                            Мы вправе принять меры в случае выявления нарушений, либо действий по приготовлению к
                            нарушению законодательства, прав третьих лиц или Условий и Правил Услуги 24/7, в том числе
                            в случае выявления признаков злоупотребления сервисами Услуги 24/7 (например, м
                            анипулирования поисковой выдачей, обхода Правил Услуги 24/7, нецелевого использования
                            функциональностей Услуги 24/7), с использованием вашего профиля или ваших технических
                            средств: <b>ограничить доступ к вашему профилю</b> или какой-то функциональности Услуги 24/7,
                            в том числе временно, например, ограничить доступ к мессенджеру Услуги 24/7, ограничить
                            выдачу вашего контента в результатах поиска, скрыть отображение объявления на карте,
                            отклонить или заблокировать ваш контент, ограничить доступ к Услуги 24/7 с определённых
                            IP-адресов и телефонных номеров.
                            <br/>
                            <br/>
                            Мы можем <b>ограничить доступ к профилю</b> в случае поступления в отношении такого профиля
                            жалоб других пользователей, выявления действий, приводящих к спаму, неоднократных или
                            злостных нарушений Условий использования и Правил Услуги 24/7 (включая размещение объявлений
                            и указание цен, не соответствующих действительности), взлома профиля, непредоставления
                            пользователем документов или информации по нашему запросу, размещения недостоверных
                            отзывов, ликвидации пользователя – юридического лица, а также если мы заметим в поведении
                            пользователя на Услуги 24/7 признаки мошенничества, навязывания дополнительных услуг и
                            условий, либо если пользователь зарегистрировал новый профиль на Услуги 24/7 вместо
                            заблокированного ранее
                            <br/>
                            <br/>
                            В исключительных случаях, при полном устранении пользователем совершенных им нарушений, мы
                            можем восстановить доступ к профилю, в целях поддержания лояльности пользователя Услуги 24/7.
                            <br/>
                            <br/>
                            Мы не несём ответственности за возможные убытки, причинённые пользователям в связи с
                            принятием мер для предотвращения и прекращения нарушений на Услуги 24/7. Рекомендуем
                            сохранять резервную копию вашего контента на вашем устройстве.
                            <br/>
                            <br/>
                            За нарушение законодательства или прав третьих лиц при использовании Услуги 24/7 вы можете
                            нести административную, уголовную или гражданско-правовую ответственность.
                        </span>
                    </div>
                    <div className="privacy-policy__column" id="section10">
                        <h2 className="privacy-policy__content-title">10. Заключительные положения</h2>
                        <span className="privacy-policy__content-text">
                            Функциональность Услуги 24/7 в любой момент может изменяться, дополняться или прекращаться
                            без предварительного уведомления пользователей. Использование Услуги 24/7 предлагается в
                            режиме «как есть», то есть в том виде и объёме, в каком Компания предоставляет
                            функциональные возможности Услуги 24/7 в момент обращения к ним. Мы не несём ответственности
                            за временные сбои и перерывы в работе Услуги 24/7 и вызванные ими потери информации. Мы не
                            несем ответственность за любой косвенный, случайный, неумышленный ущерб, включая упущенную
                            выгоду или потерянные данные, вред чести, достоинству, деловой репутации или иной вред,
                            причинённый в связи с использованием Услуги 24/7 или отдельных функциональностей Услуги 24/7.
                            Мы не несем ответственность за противоправные и недобросовестные действия третьих лиц,
                            использовавших Услуги 24/7.
                            <br/>
                            <br/>
                            Услуги 24/7 предназначен для пользователей в Российской Федерации, поэтому некоторая
                            функциональность может быть ограничена или недоступна за её пределами.
                            <br/>
                            <br/>
                            Услуги 24/7 может содержать ссылки на сайты и предложения третьих лиц. Мы не контролируем и
                            не несём ответственность за доступность, содержание и законность таких сторонних сайтов,
                            предложений и последствия их использования.
                            <br/>
                            <br/>
                            При разрешении всех споров между Компанией и пользователями применяется законодательство
                            Российской Федерации. Все споры должны быть переданы на рассмотрение в суд в соответствии с
                            территориальной подсудностью по месту нахождения Компании (г. Омск), если иное не
                            предусмотрено законодательством.
                            <br/>
                            <br/>
                            Мы обновляем Условия использования Услуги 24/7 по мере необходимости. Рекомендуем вам
                            периодически посещать страницу, на которой они размещены: <Link to="/terms" className="privacy-policy__content-link">https://услуги24-7.рф/terms</Link>.
                            <br/>
                            <br/>
                            Продолжая пользоваться Услуги 24/7 после изменения Условий, вы подтверждаете согласие с
                            внесёнными в них изменениями.
                            <br/>
                            <br/>
                            Если у вас остались вопросы об Условиях использования Услуги 24/7, вы можете поискать
                            ответы в Помощи или обратиться к специалистам службы поддержки.
                            <br/>
                            <br/>
                            Вы также можете направить нам претензионное обращение в письменной форме по адресу,
                            представленному ниже.
                            <br/>
                            <br/>
                            Мы обязательно рассмотрим его и дадим ответ в срок, согласно действующему законодательству.
                            <br/>
                            <br/>
                            ИП Щербинин Н.В.
                            <br/>
                            ИНН: 550403079841
                        </span>
                    </div>
                </div>
            </div>
            {scroll > 0 && (
                <button className="btn-up" onClick={scrollToTop}>⇪</button>
            )}
            <Footer/>
        </>
    )
}

export default TermsOfUse