import "./AuthorizationFirstPage.css"
import {useState} from "react";
import AuthorizationLastPage from "../AuthorizationLastPage/AuthorizationLastPage";
import axios from "axios";
import API_URL from "../../../index";

const AuthorizationFirstPage = () => {
    const [state, setState] = useState(1)
    const [type, setType] = useState('')
    const [phone, setPhone] = useState("")
    // const [onFocus, setOnFocus] = useState(false)

    const [tgURL, setTgURL] = useState('')
    const [code, setCode] = useState('')

    const handler_phone_data = (event) => {
        event.preventDefault();
    };

    const handleInputChange = (event) => {
        const value = event.target.value;
        // Удаляем все нецифровые символы
        const sanitizedValue = value.replace(/[^0-9]/g, "");
        setPhone(sanitizedValue);
        handler_phone_data(event);
    };

    const login = async () => {
        if (phone) {
            await axios.post(`${API_URL}/user/login`, {
                    "telephone_number": formatPhoneNumber(phone),
                }
            ).then(response => {
                if (response.status === 200 || response.status === 201) {
                    setState(2)
                    setTgURL(response.data.url);
                    setCode(response.data.code);
                }
            })
                .catch((error) => {
                    console.log(error)
                })
        }
    }

    const formatPhoneNumber = (phone) => {
        // Удаляем все символы, кроме цифр
        const cleanedPhone = phone.replace(/\D/g, '');

        // Приводим номер к формату +7, если он начинается с 7 или 8
        if (cleanedPhone.startsWith('7') || cleanedPhone.startsWith('8')) {
            return `+7${cleanedPhone.slice(1)}`;
        }

        // Если номер уже в правильном формате или не требует изменений, возвращаем его как есть
        return phone;
    };

    return (
        <>
            {state === 1 ? (
                <div className="authorization-first">
                    <h2 className="authorization-first__title">Авторизация</h2>
                    <div className="authorization-first__input-container">
                        <input
                            onChange={handleInputChange}
                            // onFocus={() => setOnFocus(true)}  // Устанавливаем фокус
                            // onBlur={() => setOnFocus(false)}  // Снимаем фокус, если это необходимо
                            type="tel"
                            inputMode="numeric"
                            placeholder="Телефон"
                            pattern="[0-9]*"
                            // style={{
                            //     color: phone.length < 11 && !onFocus ? "red" : phone.length === 0 || !onFocus ? "black" : phone.length < 11 ? "red" : "green"
                            // }}  // Корректный тернарный оператор
                            maxLength={11}
                            value={phone}
                            className="authorization-first__phone-input"
                        />
                    </div>
                    <button className="confirmation-btn" onClick={() => {
                        login()
                        setType('tg')
                    }}>Подтвердить через Telegram
                    </button>
                    {/*<button className="confirmation-btn" onClick={() => {*/}
                    {/*    setState(2);*/}
                    {/*    setType('phone')*/}
                    {/*}}>Подтвердить через входящий звонок*/}
                    {/*</button>*/}
                </div>
            ) : (
                <div className="authorization-first__and-back">
                    <AuthorizationLastPage type={type} phone={formatPhoneNumber(phone)} code={code} tgURL={tgURL}/>
                    <button className="back-btn" onClick={() => setState(1)}>
                        <img src="/new-imgs/Arrow-back.svg" alt="Back" className="back-btn__img"/>Назад
                    </button>
                </div>
            )}
        </>
    )
}

export default AuthorizationFirstPage