import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import {Link} from "react-router-dom";
import "./PrivacyPolicy/PrivacyPolicy.css"

const RulesPage = () => {
    return (
        <>
            <Header/>
            <div className="privacy-policy">
                <div className="container">
                    <div className="rules__row">
                        <div className="rules__row-left">
                            <h2 className="privacy-policy__title">Правила Услуги 24/7</h2>
                            <div className="privacy-policy__column">
                                <span className="privacy-policy__content-text">
                                    Здесь собраны правила, по которым живут Услуги 24/7: заказчики размещают объявления,
                                    а исполнители выполняют.
                                </span>
                                <div className="rules-page__column">
                                    <ul className="privacy-policy__content-list">
                                        <li className="privacy-policy__content-item">
                                            <Link to="/terms" className="privacy-policy__content-link">Условия
                                                использования Услуги 24/7</Link>
                                        </li>
                                        <li className="privacy-policy__content-item">
                                            <Link to="/listing-policy" className="privacy-policy__content-link">Правила
                                                размещения объявлений</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="rules__row-right">
                            <img src="/services24-logo-png.png" alt="Logo" className="rules__img"/>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default RulesPage