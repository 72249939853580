import "./BlockRegOrAuth.css"

const BlockRegOrAuth = ({title, btn}) => {

    return (
        <>
            <div className="block-reg-or-auth">
                <h3 className="block-reg-or-auth__question">{title}</h3>
                <button className="block-reg-or-auth__btn"
                        onClick={() => btn === "Войти" ? window.location.href = "/authorization" : window.location.href = "/registration"}>{btn}</button>
            </div>
        </>
    )
}

export default BlockRegOrAuth