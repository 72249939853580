import React, {useEffect, useState, useRef} from "react";
import "./AddBlock.css"
import API_URL from "../../index";
import axios from "axios";

const AddBlock = ({
                      selectedSubCategory, // выбранная подкатегория
                      listSubCategories, // список из названий подкатегорий
                      selectedCity, // город пользователя
                      categoryName, // выбранная категория
                      type, // тип создание или редактирование
                      descriptionEdit, // старое описание для редактирования
                      applicationId, // id заявки для редактирования
                      applications, // список заявок для редактирования
                      imagesEdit // список фото для редактирования
                  }) => {

    const [inputValue, setInputValue] = useState(selectedSubCategory || '');
    const [inputCityValue, setInputCityValue] = useState(selectedCity || '');

    const [filteredOptions, setFilteredOptions] = useState([]);
    const [filteredCityOptions, setFilteredCityOptions] = useState([]);

    const [showOptions, setShowOptions] = useState(false);
    const [showCityOptions, setShowCityOptions] = useState(false);

    const [sortedCities, setSortedCities] = useState([])

    const [description, setDescription] = useState(type === 'edit' ? descriptionEdit : '');
    const [isListening, setIsListening] = useState(false);
    const inputRef = useRef(null);
    const [images, setImages] = useState([]);
    const [imagesJPEG, setImagesJPEG] = useState(type === "edit" ? imagesEdit : [])
    const fileInputRef = useRef(null);
    const [period, setPeriod] = useState(7)
    const [price, setPrice] = useState(0)

    const handleChange = (e, typeImage) => {
        if (type !== "edit") {
            const value = e.target.value;
            if (typeImage === "category") {
                setInputValue(value);
                setFilteredOptions(listSubCategories.filter(option =>
                    option.toLowerCase().includes(value.toLowerCase())
                ));
                setShowOptions(true);
            } else if (typeImage === "city") {
                setInputCityValue(value);
                setFilteredCityOptions(sortedCities.filter(option =>
                    option.toLowerCase().includes(value.toLowerCase())
                ));
                setShowCityOptions(true);
            }
        }
    };

    const handleOptionClick = (option, typeImage) => {
        if (type !== "edit") {
            if (typeImage === "category") {
                setInputValue(option);
                setShowOptions(prevState => !prevState);
            } else if (typeImage === "city") {
                setInputCityValue(option);
                setShowCityOptions(prevState => !prevState);
            }
        }
    };

    const handleImageClick = (typeImage) => {
        if (type !== "edit") {
            if (typeImage === "category") {
                setShowOptions(prevState => !prevState);
                setFilteredOptions(listSubCategories);  // показываем все опции при клике на иконку
            } else if (typeImage === "city") {
                setShowCityOptions(prevState => !prevState);
                setFilteredCityOptions(sortedCities);  // показываем все опции при клике на иконку
            }
        }
    };

    const handleFocus = (typeImage) => {
        if (type !== "edit") {
            if (typeImage === "category") {
                setShowOptions(true);
                setFilteredOptions(listSubCategories);  // показываем все опции при фокусировке на input
            } else if (typeImage === "city") {
                setShowCityOptions(true);
                setFilteredCityOptions(sortedCities);  // показываем все опции при фокусировке на input
            }
        }
    };

    const get_city = async () => {
        try {
            await axios.get(`${API_URL}/application/get-cities?offset=0`, {withCredentials: true})
                .then(response => {
                    if (response.status === 200) {
                        const cities = response.data;
                        const options = cities.map(city => city.name);
                        const sortedOptions = options.sort((a, b) => a.localeCompare(b))
                        setSortedCities(sortedOptions)
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        } catch (error) {
            console.log(error)
        }
    }

    const get_categories = async () => {
        await axios.get(`${API_URL}/application/get-categories?offset=0&city_name=${inputCityValue}`, {withCredentials: true})
            .then(response => {
                if (response.status === 200) {
                    const data = response.data;
                    setPrice(findSubcategoryCost(categoryName, inputValue, data))
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const change_city = async (option) => {
        if (option) {
            await axios.post(`${API_URL}/user/change-city?city=${option}`)
                .then(response => {
                    if (response.status === 200) {
                        setShowCityOptions(false)
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }


    const handleStartListening = () => {
        // Проверяем поддержку Web Speech API
        if (!('webkitSpeechRecognition' in window)) {
            alert('Ваш браузер не поддерживает Web Speech API');
            return;
        }

        const recognition = new window.webkitSpeechRecognition();
        recognition.lang = 'ru-RU'; // Установите нужный язык
        recognition.continuous = false; // Останавливаем запись после первой паузы
        recognition.interimResults = false; // Ожидаем завершение распознавания, а не выводим промежуточные результаты

        recognition.onstart = () => {
            setIsListening(true);
        };

        recognition.onend = () => {
            setIsListening(false);
        };

        recognition.onresult = (event) => {
            const result = event.results[0][0].transcript;
            setDescription(description + result);
        };

        recognition.onerror = (event) => {
            console.error('Ошибка распознавания речи: ', event.error);
            setIsListening(false);
        };

        recognition.start();

        inputRef.current.focus();
    };

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);

        const imageUrls = files.map(file => URL.createObjectURL(file));

        setImagesJPEG(prevImages => [...prevImages, ...imageUrls]);

        // Сохраняем файлы напрямую в состояние
        setImages(prevImages => [...prevImages, ...files]);
    };

    // Обработчик кнопки
    const handleButtonClick = () => {
        fileInputRef.current.click(); // Программно активируем input
    };

    const deleteImageClick = async (index) => {
        if (type === "edit") {
            delete_image_to_applic(index)
        }
        setImages(prevImages => prevImages.filter((_, i) => i !== index));
    };

    const delete_image_to_applic = async (index) => {
        const applic = applications.find((applic) => applic["id"] === applicationId);
        const image_id = applic["images"][index]["id"]
        try {
            const response = await axios.post(
                `${API_URL}/application/delete-image-from-application?application_id=${applicationId}&image_id=${image_id}`,
                {},
                {withCredentials: true,}
            );

            if (response.status === 200) {
                setImagesJPEG(prevImages => prevImages.filter((_, i) => i !== index));
            }
        } catch (error) {
            console.error('Ошибка при добавлении изображения:', error);
        }
    }

    const create_application = async () => {
        if (images.length > 0) {
            if (categoryName && inputValue && description && inputCityValue && type !== "edit") {
                await axios.post(`${API_URL}/application/create-application`,
                    {
                        description: description,
                        category_name: categoryName,
                        subcategory_name: inputValue,
                        city_name: inputCityValue,
                        period: period,
                    }, {withCredentials: true}
                    // {withCredentials: true}
                ).then(response => {
                    if (response.status === 200) {
                        const id = response.data["id"]
                        images.forEach((image) => {
                            add_image_to_applic(id, image);
                        });
                    }
                })
                    .catch((error) => {
                        console.log(error)
                    })
            }
        } else {
            if (categoryName && inputValue && description && inputCityValue && type !== "edit") {
                await axios.post(`${API_URL}/application/create-application`, {
                        description: description,
                        category_name: categoryName,
                        subcategory_name: inputValue,
                        city_name: inputCityValue,
                        period: period,
                    }, {withCredentials: true}
                ).then(response => {
                    if (response.status === 200) {
                        const id = response.data["id"]
                        pay_application(id)
                    }
                })
                    .catch((error) => {
                        console.log(error)
                    })
            }
        }
    }

    const pay_application = async (id) => {
        await axios.post(`${API_URL}/payment/pay-application?application_id=${id}`, {}, {withCredentials: true}
        ).then(response => {
            if (response.status === 200) {
                // window.open(response.data, '_blank', 'noopener,noreferrer');
                window.location.href = response.data
            }
        })
    }

    const add_image_to_applic = async (id, image) => {
        if (!image) {
            console.error("Файл не передан");
            return;
        }

        const formData = new FormData();
        formData.append('image', image); // Имя должно совпадать с ожидаемым параметром на сервере

        try {
            const response = await axios.post(
                `${API_URL}/application/add-image-to-application?application_id=${id}`,
                formData,
                {
                    withCredentials: true,
                }
            );

            if (response.status === 200) {
                if (type !== "edit") {
                    pay_application(id);
                } else {
                    window.location.href = "/applications"
                }
            }
        } catch (error) {
            console.error('Ошибка при добавлении изображения:', error);
        }
    };

    const update_application = async () => {
        if (descriptionEdit === description) {
            const imagesRemovedOnly = imagesJPEG.every(image => imagesEdit.includes(image)) && imagesEdit.length >= imagesJPEG.length;
            if (imagesRemovedOnly) {
                window.location.href = "/applications";  // Переходим на /applications
            } else {
                const missingImage = images.filter(item => !imagesEdit.includes(item));

                missingImage.forEach((image) => {
                    add_image_to_applic(applicationId, image);
                });
            }
        } else {
            await axios.post(`${API_URL}/application/update-application`, {
                    description: description,
                }, {withCredentials: true}
            ).then(response => {
                if (response.status === 200) {
                    window.location.href = "/applications"
                }
            })
                .catch((error) => {
                    console.log(error)
                })
        }
    }


    const choice_period = (id) => {
        const btn = document.querySelector(`.add-applic__period-btn-item-${id}`)
        if (!btn.classList.contains("active")) {
            btn.classList.add("active")
            setPeriod(id)
            if (id === 1) {
                const btn3 = document.querySelector(`.add-applic__period-btn-item-3`)
                const btn7 = document.querySelector(`.add-applic__period-btn-item-7`)
                if (btn3.classList.contains("active")) {
                    btn3.classList.remove("active")
                }
                if (btn7.classList.contains("active")) {
                    btn7.classList.remove("active")
                }
            } else if (id === 3) {
                const btn1 = document.querySelector(`.add-applic__period-btn-item-1`)
                const btn7 = document.querySelector(`.add-applic__period-btn-item-7`)
                if (btn1.classList.contains("active")) {
                    btn1.classList.remove("active")
                }
                if (btn7.classList.contains("active")) {
                    btn7.classList.remove("active")
                }
            } else if (id === 7) {
                const btn3 = document.querySelector(`.add-applic__period-btn-item-3`)
                const btn1 = document.querySelector(`.add-applic__period-btn-item-1`)
                if (btn3.classList.contains("active")) {
                    btn3.classList.remove("active")
                }
                if (btn1.classList.contains("active")) {
                    btn1.classList.remove("active")
                }
            }
        } else {

        }
    }

    const findSubcategoryCost = (categoryName, subCategoryName, dataFind) => {
        const category = dataFind.find((cat) => cat["name"] === categoryName);

        const subCategory = category["sub_categories"].find((subCat) => subCat["name"] === subCategoryName);

        return subCategory["cost"]
    }

    useEffect(() => {
        change_city(inputCityValue);
        get_categories()
    }, [inputValue, inputCityValue])

    useEffect(() => {
        setInputValue(selectedSubCategory);
    }, [selectedSubCategory]);

    useEffect(() => {
        get_city()
    }, [])


    return (
        <>
            <div className="add-applic__column">
                <h3 className="add-applic__title">{type === "add" ? "Создание объявления" : "Редактирование объявления"}</h3>
                {/*<span className="add-applic__error" style={{height: 20, color: "red"}}></span>*/}
                <div className="add-applic__container">
                    <div className="add-applic__category-and-description">
                        <div className="add-applic__dropdawn-input-container"
                             onClick={() => handleImageClick("category")}>
                            <input
                                type="text"
                                placeholder="Выберите подкатегорию"
                                className="add-applic__dropdown-input"
                                value={inputValue}
                                readOnly
                                onChange={(event) => handleChange(event, "category")}
                                onFocus={(event) => handleFocus(event, "category")}
                                onBlur={() => setTimeout(() => setShowOptions(false), 100)} // delay to allow click
                            />
                            <img
                                className="add-applic__dropdown-input-img"
                                src="/arrow-down.svg"
                                alt="Open"
                            />
                            {showOptions && filteredOptions.length > 0 ? (
                                <div className="add-applic__options-container">
                                    {filteredOptions.map((option, index) => (
                                        <div
                                            key={index}
                                            className="add-applic__option"
                                            onClick={() => handleOptionClick(option, "category")}
                                        >
                                            {option}
                                        </div>
                                    ))}
                                </div>
                            ) : inputValue ? showOptions && filteredOptions.length === 0 && (
                                <div className="add-applic__options-container">
                                    <div className="add-applic__option">Такой подкатегории не найдено</div>
                                </div>
                            ) : null}
                        </div>
                        {images.length > 0 && (
                            <span style={{fontSize: 12, width: "100%", textAlign: "center"}}>Изображения принимаются только в формате JPEG</span>
                        )}
                        <div className="add-applic__description">
                            <div className="add-applic__description-container">
                                <textarea
                                    value={description}
                                    ref={inputRef}
                                    onChange={(e) => setDescription(e.target.value)}
                                    className="add-applic__description-textarea"
                                    placeholder="Расскажите подробнее о заказе, что и где нужно сделать, бюджет, особенности. Прикрепите фото, если необходимо"
                                ></textarea>
                                <div style={{paddingBottom: "40px"}}>
                                    <input
                                        type="file"
                                        accept="image/jpeg"
                                        multiple
                                        onChange={handleFileChange}
                                        ref={fileInputRef} // Ссылка на input
                                        style={{display: 'none'}} // Скрываем input
                                    />
                                    <div>
                                        {imagesJPEG.length > 0 && (
                                            <h2 style={{fontSize: 12}}>Предпросмотр изображений:</h2>
                                        )}
                                        <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                            {imagesJPEG.map((image, index) => (
                                                <div
                                                    key={index}
                                                    style={{
                                                        margin: '2px',
                                                        position: 'relative',
                                                        display: 'inline-block'
                                                    }}
                                                >
                                                    <img
                                                        src={
                                                            type === "edit" ?
                                                                typeof image === "object" ? (`${API_URL}/api/static/${image["image"]["path"]}`) : image :
                                                                image
                                                        }
                                                        alt={`Preview ${index + 1}`}
                                                        style={{
                                                            maxWidth: '44px',
                                                            height: 'auto',
                                                            display: 'block'
                                                        }}
                                                    />
                                                    <button
                                                        onClick={(e) => {
                                                            e.stopPropagation(); // Останавливаем событие клика, чтобы не активировать основной обработчик
                                                            deleteImageClick(index);
                                                        }}
                                                        style={{
                                                            position: 'absolute',
                                                            top: '50%',
                                                            left: '50%',
                                                            transform: 'translate(-50%, -50%)',
                                                            backgroundColor: 'rgba(0, 0, 0, 0.6)',
                                                            border: 'none',
                                                            borderRadius: '50%',
                                                            width: '25px',
                                                            height: '25px',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            color: 'white',
                                                            fontSize: '20px',
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        ×
                                                    </button>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="add-applic__buttons">
                                    <button
                                        className="add-applic__btn-img-container"
                                        onClick={handleButtonClick} // Обработчик клика по кнопке
                                    >
                                        <img
                                            src="/skrepka.svg"
                                            alt="Clip"
                                            className="add-applic__btn-img"
                                            style={{height: "30px"}}
                                        />
                                    </button>
                                    <button className="add-applic__btn-img-container" onClick={handleStartListening}
                                            disabled={isListening}>
                                        <img src="/micro.svg" alt="Voice input" className="add-applic__btn-img"/>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="add-applic__city-and-price">
                        <div className="add-applic__dropdawn-input-container" onClick={() => handleImageClick("city")}>
                            <input
                                type="text"
                                placeholder="Выберите город"
                                className="add-applic__dropdown-input"
                                readOnly
                                value={inputCityValue}
                                onChange={(event) => handleChange(event, "city")}
                                onFocus={(event) => handleFocus(event, "city")}
                                onBlur={() => setTimeout(() => setShowCityOptions(false), 100)} // delay to allow click
                            />
                            <img
                                className="add-applic__dropdown-input-img city"
                                src="/arrow-down.svg"
                                alt="Open"
                            />
                            {showCityOptions && filteredCityOptions.length > 0 ? (
                                <div className="add-applic__options-container">
                                    {filteredCityOptions.map((option, index) => (
                                        <div
                                            key={index}
                                            className="add-applic__option"
                                            onClick={() => handleOptionClick(option, "city")}
                                        >
                                            {option}
                                        </div>
                                    ))}
                                </div>
                            ) : inputCityValue ? showCityOptions && filteredCityOptions.length === 0 && (
                                <div className="add-applic__options-container">
                                    <div className="add-applic__option">Такого города не найдено</div>
                                    {/*<img className="add-applic__dropdown-input-img city"*/}
                                    {/*     src="/arrow-down.svg" alt="Open" onClick={() => handleImageClick("city")}*/}
                                    {/*/>*/}
                                </div>
                            ) : null}
                        </div>
                        {type !== "edit" && (
                            <div className="add-applic__period">
                                <h3 className="add-applic__period-title">На сколько дней выложить объявление?</h3>
                                <div className="add-applic__period-btn-list">
                                    <button className="add-applic__period-btn-item-1" onClick={() => choice_period(1)}>1
                                    </button>
                                    <button className="add-applic__period-btn-item-3" onClick={() => choice_period(3)}>3
                                    </button>
                                    <button className="add-applic__period-btn-item-7 active"
                                            onClick={() => choice_period(7)}>7
                                    </button>
                                </div>
                            </div>
                        )}
                        {type !== "edit" && (
                            <div className="add-applic__price">
                                {price} руб.
                            </div>
                        )}
                        <button className="block-reg-or-auth__btn add-btn"
                                onClick={type === "add" ? create_application : update_application}>{type === "add" ? "Оплатить и разместить" : "Сохранить изменения"}</button>
                    </div>
                </div>
            </div>

        </>
    )
}

export default AddBlock