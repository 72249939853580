import "./BlockTG.css"

const BlockTG = () => {
    return (
        <>
            <div className="block-tg">
                <h3 className="block-tg__question">Возникли вопросы?</h3>
                <button className="block-tg__btn">
                    <img src="/new-imgs/telegram-blue.svg" alt="Logo" className="block-tg__img"/>
                    Тех. поддержка
                </button>
            </div>
        </>
    )
}

export default BlockTG