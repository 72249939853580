import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import "./Authorization.css"
import BlockTG from "../../components/BlockTG/BlockTG";
import BlockRegOrAuth from "../../components/BlockRegOrAuth/BlockRegOrAuth";
import AuthorizationFirstPage from "./AuthorizationFirstPage/AuthorizationFirstPage";
import axios from "axios";
import {useState} from "react";

const Authorization = () => {
    const windowWidth = window.innerWidth;
    // if (searchString.some((char) => pass.includes(char))) {
    //     error_handler('Неправильный логин или пароль', 'reg');

    // } else {
    //     await axios.post('https://vinyldiscount.ru/fastapi/auth/login', {
    //             phone: phone,
    //         },
    //         {withCredentials: true})
    //         .then(response => {
    //             // if (response.status === 200) {
    //             //     toast.success("Авторизация прошла успешно")
    //             //     setTimeout(() => {
    //             //         window.location.replace("/")
    //             //     }, 1300)
    //             // }
    //         })
    //         .catch((error) => {
    //             // error_handler('Неправильный логин или пароль', 'reg');
    //             // Cookies.set('Authorization', 'false')
    //         })
    // }
    // }

    return (
        <>
            <Header/>
            <div className="authorization">
                <div className="container authorization-container">
                    <div className="authorization__row">
                        {windowWidth > 780 && (
                            <ul className="authorization__promo-list">
                                <li className="authorization__promo-item">
                                    <span className="authorization__promo-info">Здесь может быть ваша реклама</span>
                                </li>
                                <li className="authorization__promo-item">
                                    <span className="authorization__promo-info">Здесь может быть ваша реклама</span>
                                </li>
                                <li className="authorization__promo-item">
                                    <span className="authorization__promo-info">Здесь может быть ваша реклама</span>
                                </li>
                            </ul>
                        )}
                        <AuthorizationFirstPage/>
                        <div className="authorization__info-column">
                            <div className="authorization__reg-block">
                                <BlockRegOrAuth
                                    title={"Нет аккаунта?"}
                                    btn={"Регистрация"}
                                />
                                <BlockTG/>
                            </div>
                            {windowWidth > 780 && (
                                <li className="authorization__promo-item" style={{width:'100%'}}>
                                    <span className="authorization__promo-info">Здесь может быть ваша реклама</span>
                                </li>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default Authorization