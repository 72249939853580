import React, {useEffect, useState} from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import "./MyApplic.css"
import axios from "axios";
import API_URL from "../../index";
import AddBlock from "../../components/AddBlock/AddBlock";
import TariffBlock from "../../components/TariffBlock/TariffBlock";

const MyApplic = () => {
    const [myApplic, setMyApplic] = useState([])
    const [selectedApplicId, setSelectedApplicId] = useState(null)
    const [selectedSubCategory, setSelectedSubCategory] = useState("")
    const [listSubCategories, setListSubCategories] = useState([])
    const [selectedCity, setSelectedCity] = useState("")
    const [description, setDescription] = useState("")
    const [selectedCategory, setSelectedCategory] = useState("")

    const get_my_applications = async () => {
        await axios.get(`${API_URL}/application/get-my-applications?offset=0`, {withCredentials: true})
            .then(response => {
                if (response.status === 200) {
                    setMyApplic(response.data)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const confirmation_archiving = async (id) => {
        const section = document.querySelector(".confirmation-archiving")
        if (section.classList.contains("open")) {
            section.classList.remove("open")
            setSelectedApplicId(null)
        } else {
            section.classList.add("open")
            setSelectedApplicId(id)
        }
    }

    const close_application = async () => {
        if (selectedApplicId >= 0) {
            await axios.post(`${API_URL}/application/close-application?application_id=${selectedApplicId}`)
                .then(response => {
                    if (response.status === 200) {
                        confirmation_archiving()
                        get_my_applications()
                    } else {
                        confirmation_archiving()
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }

    const formatDate = (dateString) => {
        // Создаем объект даты из строки
        const date = new Date(dateString);

        // Получаем день, месяц и год
        const day = String(date.getDate()).padStart(2, '0'); // День с ведущим нулем
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Месяц с ведущим нулем (нумерация месяцев с 0)
        const year = date.getFullYear(); // Год

        // Возвращаем дату в нужном формате
        return `${day}.${month}.${year}`;
    }

    const open_add_applic = (index, item) => {
        const section = document.querySelector(".add-applic")
        const black = document.querySelector(".window-closer-applic")
        if (index >= 0) {
            setSelectedApplicId(item["id"])
            setSelectedSubCategory(item["subcategory"]["name"])
            setListSubCategories(item["category"]["sub_categories"].map(subCategory => subCategory.name))
            setDescription(item["description"])
            setSelectedCity(item["city"]["name"])
            setSelectedCategory(item["category"]["name"])
        } else {
            setSelectedApplicId(0)
            setSelectedSubCategory("")
            setListSubCategories([])
            setSelectedCity("")
            setDescription("")
        }
        if (section.classList.contains("open")) {
            section.classList.remove("open");
            black.classList.remove("open");
        } else {
            section.classList.add("open");
            black.classList.add("open");
        }
    }

    useEffect(() => {
        get_my_applications()
    }, [])


    return (
        <>
            <Header/>
            <div className="my-applic">
                <div className="container my-applic__container">
                    <h2 className="my-applic__title">Мои объявления</h2>
                    {myApplic && myApplic.length > 0 ? (
                        <ul className="applic__list">
                            {myApplic && myApplic.map((item, index) => (
                                <li key={index} className="applic__item">
                                    {item["images"] && item["images"][0] ? (
                                        <img src={`${API_URL}/api/static/${item["images"][0]["image"]["path"]}`}
                                             alt="File"
                                             className="applic__img"/>
                                    ) : (
                                        <div style={{height: 0}}></div>
                                    )}
                                    <div className="applic__item-info">
                                        <h3 className="applic__item-category">{item["category"]["name"]}</h3>
                                        <h4 className="applic__item-title">{item["subcategory"]["name"]}</h4>
                                        <span className="applic__item-description">{item["description"]}</span>
                                    </div>
                                    <div className="date-of-creation-and-status">
                                        <span className="date-of-creation">{formatDate(item["date_of_creation"])}</span>
                                        <span
                                            className="status">{item["status"] === "archive" ? "В архиве"
                                            : item["status"] === "active" ? "Активно"
                                                : item["status"] === "pending" && "Ожидает оплату"}</span>
                                    </div>
                                    <div className="buttons-row">
                                        <button className="phone-btn" onClick={() => open_add_applic(index, item)}>
                                            <img src="/edit.svg" alt="Edit" className="edit-btn__img"/>
                                        </button>
                                        <hr/>
                                        <button className="tg-btn" onClick={() => confirmation_archiving(item["id"])}>
                                            <img src="/arhive.svg" alt="Arhive" className="tg-btn__img"/>
                                        </button>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <ul className="applic__list" style={{
                            fontSize: 24,
                            fontWeight: 600,
                            color: "white",
                            paddingTop: "40px",
                            textAlign: "center"
                        }}>
                            Вы пока не выкладывали объявления
                        </ul>
                    )}
                    <div className="confirmation-archiving">
                        <div className="confirmation-archiving__column">
                            <div className="confirmation-archiving__btn-container">
                                <button className="confirmation-archiving__btn-close"
                                        onClick={confirmation_archiving}>×
                                </button>
                            </div>
                            <span className="confirmation-archiving__text">Вы уверены, что хотите поместить это объявление в архив?</span>
                            <div className="confirmation-archiving__btn-container">
                                <button onClick={close_application} className="confirmation-archiving__btn">Да</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="tariff-block">
                <TariffBlock/>
            </div>
            <section className="window-closer-applic" onClick={() => open_add_applic()}></section>
            <div className="add-applic">
                {selectedApplicId && (
                    <AddBlock type={"edit"}
                              selectedSubCategory={selectedSubCategory}
                              listSubCategories={listSubCategories}
                              selectedCity={selectedCity}
                              categoryName={selectedCategory}
                              applicationId={selectedApplicId}
                              descriptionEdit={description}
                              applications={myApplic}
                              imagesEdit={myApplic.find((applic) => applic["id"] === selectedApplicId)["images"]}
                    />
                )}
            </div>
            <Footer/>
        </>
    )
}

export default MyApplic