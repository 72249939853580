import "../PrivacyPolicy/PrivacyPolicy.css"
import {useEffect, useState} from "react";
import Header from "../../../components/Header/Header";
import {Link} from "react-router-dom";
import Footer from "../../../components/Footer/Footer";

const ServicesAgreement = () => {

    const [scroll, setScroll] = useState(0)
    const handleScroll = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({behavior: 'smooth'});
        }
        // Удаляем якорь из URL
        window.history.replaceState(null, null, ' ');
    };

    const scrollToTop = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    };


    useEffect(() => {
        const handleScroll = () => {
            setScroll(window.scrollY);
        };

        // Добавляем обработчик события скролла
        window.addEventListener('scroll', handleScroll);

        // Убираем обработчик при размонтировании компонента
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        scrollToTop();
    }, []);

    return (
        <>
            <Header/>
            <div className="privacy-policy">
                <div className="container">
                    <h2 className="privacy-policy__title">Оферта на оказание услуг</h2>
                    <div className="privacy-policy__column">
                        <div className="privacy-policy__content">
                            <h2 className="privacy-policy__content-title">Содержание</h2>
                            <span className="privacy-policy__content-text">
                                Настоящий документ является предложением ИП Щербинин Н.В. (далее – «<b>Компания</b>»)
                                в адрес зарегистрированных пользователей Услуги 24/7 (далее – «<b>пользователи</b>»,
                                «<b>вы</b>») заключить рамочный договор (далее – «<b>Договор</b>») на указанных ниже
                                условиях (далее – «<b>оферта</b>»).
                                <br/>
                                <br/>
                                В оферте могут встречаться термины, как они определены в <Link className="privacy-policy__content-link" to="/terms">Условиях использования</Link>
                                Услуги 24/7.
                                <br/>
                                <br/>
                            </span>
                            <ul className="privacy-policy__content-list">
                                <li className="privacy-policy__content-item">
                                    <Link to="#" onClick={() => handleScroll("section1")}
                                          className="privacy-policy__content-link">Предмет Договора</Link>
                                </li>
                                <li className="privacy-policy__content-item">
                                    <Link to="#" onClick={() => handleScroll("section2")}
                                          className="privacy-policy__content-link">Заключение Договора</Link>
                                </li>
                                <li className="privacy-policy__content-item">
                                    <Link to="#" onClick={() => handleScroll("section3")}
                                          className="privacy-policy__content-link">Общие условия предоставления сервисов
                                        Услуги 24/7</Link></li>
                                <li className="privacy-policy__content-item">
                                    <Link to="#" onClick={() => handleScroll("section4")}
                                          className="privacy-policy__content-link">Стоимость и порядок расчетов</Link>
                                </li>
                                <li className="privacy-policy__content-item">
                                    <Link to="#" onClick={() => handleScroll("section5")}
                                          className="privacy-policy__content-link">Ответственность</Link></li>
                                <li className="privacy-policy__content-item">
                                    <Link to="#" onClick={() => handleScroll("section6")}
                                          className="privacy-policy__content-link">Рассмотрение претензий</Link>
                                </li>
                                <li className="privacy-policy__content-item">
                                    <Link to="#" onClick={() => handleScroll("section7")}
                                          className="privacy-policy__content-link">Прочие условия</Link></li>
                                <li className="privacy-policy__content-item">
                                    <Link to="#" onClick={() => handleScroll("section8")}
                                          className="privacy-policy__content-link">Реквизиты Компании</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="privacy-policy__column" id="section1">
                        <h2 className="privacy-policy__content-title">1. Предмет Договора</h2>
                        <span className="privacy-policy__content-text">
                            1.1. Настоящий Договор устанавливает общие условия обязательственных отношений между
                            Компанией и пользователем при предоставлении Компанией услуг по поиску и выбору исполнителей
                            для выполнения задач («услуги») и доступа к базе данных Автотека («сублицензия»), далее
                            совместно именуемых «Услуги 24/7». Эти условия могут быть дополнены и уточнены
                            посредством заключения дополнительных соглашений или сделок на платформе Услуги 24/7 или
                            других сайтах, где Компания предоставляет свои сервисы.
                            <br/>
                            <br/>
                            1.2. Договор является договором с открытыми условиями. Ключевые параметры каждой сделки на
                            платформе Услуги 24/7 определяются пользователем через онлайн-интерфейс, где предоставляется
                            доступ к контактам заказчиков. Условия и детали сделки согласовываются сторонами
                            самостоятельно, в удобной для них форме, например, по телефону или через мессенджер Telegram.
                        </span>
                    </div>
                    <div className="privacy-policy__column" id="section2">
                        <h2 className="privacy-policy__content-title">2. Заключение Договора</h2>
                        <span className="privacy-policy__content-text">
                            2.1. Оферта считается акцептованной пользователем, а Договор между Компанией и пользователем
                            заключенным с момента оплаты пользователем сервисов Услуги 24/7 по соответствующей сделке
                            (в этом случае она совершается одновременно с заключением Договора) или с момента внесения
                            аванса в счет оплаты будущих сервисов Услуги 24/7, или с момента подтверждения реквизитов по
                            банковским ID. Условия заключенного Договора распространяются на все последующие сделки
                            между пользователем и Компанией. Договор может быть заключен только с пользователем, который
                            является дееспособным физическим лицом, либо юридическим лицом или индивидуальным
                            предпринимателем, зарегистрированным в Российской Федерации.
                            <br/>
                            <br/>
                            2.2. Каждый заказ на использование конкретного сервиса Услуги 24/7 в рамках Договора
                            является самостоятельной сделкой. Сделка во исполнение Договора может быть заключена в
                            отношении сервисов, представленных на платформе Услуги 24/7 и доступных для заказа и оплаты
                            на момент обращения пользователя к выбранному сервису. В рамках данного Договора Компания
                            не предоставляет рекламные услуги.
                            <br/>
                            <br/>
                            2.3. Оплата заказа или выполнение пользователем иных действий, предусмотренных Договором,
                            признается акцептом предложения о заключении сделки на согласованных сторонами условиях.
                            <br/>
                            <br/>
                            2.4. Пользователь оформляет каждый заказ в соответствии с условиями действующей редакции
                            оферты и условиями соответствующего сервиса. Условия всех сервисов Услуги 24/7, размещенные
                            на платформе в момент заказа, являются неотъемлемой частью оферты. Заказ оформляется в
                            режиме онлайн.
                            <br/>
                            <br/>
                            2.5. Совершая акцепт оферты, вы соглашаетесь, что выполнение определенных действий на
                            платформе Услуги 24/7, включая использование профессиональных технических инструментов,
                            выполнение команд через интерфейс Услуги 24/7 (например, нажатие кнопок, клики), совершение
                            платежа и другие аналогичные действия, означает ваше волеизъявление в отношении заказа и/или
                            активации сервиса в соответствии с указанными на Услуги 24/7 параметрами и ценой.
                        </span>
                    </div>
                    <div className="privacy-policy__column" id="section3">
                        <h2 className="privacy-policy__content-title">3. Общие условия предоставления сервисов
                            Услуги 24/7</h2>
                        <span className="privacy-policy__content-text">
                            3.1. Местом оказания услуг по настоящему Договору является территория Российской Федерации.
                            Сервисы могут быть доступны посредством онлайн-доступа из любой точки мира к ресурсам,
                            расположенным по месту регистрации Компании.
                            <br/>
                            <br/>
                            3.2. Условиями предоставления пользователю сервисов Услуги 24/7 является совершение оплаты и
                            соблюдение пользователем правил Услуги 24/7, установленных в документах, перечисленных в
                            данном пункте, а также в инструкциях, представленных в интерфейсе Услуги 24/7
                            (далее – «правила Услуги 24/7»):
                            <br/>
                            <br/>
                            — Условия использования Услуги 24/7 (<Link to="/terms" className="privacy-policy__content-link">https://услуги24-7.рф/terms</Link>);
                            <br/>
                            <br/>
                            — Документы, регулирующие условия использования сервисов Услуги 24/7;
                            <br/>
                            <br/>
                            — Документы, регламентирующие условия Тарифа (<Link to={"#"} className="privacy-policy__content-link">Ссылка временно отсутствует</Link>);
                            <br/>
                            <br/>
                            — Документы, устанавливающие правила размещения объявлений на Услуги 24/7
                            (<Link to={"/listing-policy"} className="privacy-policy__content-link">https://услуги24-7.рф/listing-policy</Link>);
                            <br/>
                            <br/>
                            — Иные документы, регламентирующие функционирование Услуги 24/7, размещенные по адресу
                            (<Link to={"#"} className="privacy-policy__content-link">Ссылка временно отсутствует</Link>).
                            <br/>
                            <br/>
                            3.3. Обязательства Компании по предоставлению сервисов согласно заказам пользователя
                            являются встречными по отношению к обязательствам пользователя по соблюдению правил
                            Услуги 24/7 и оплате сервисов. В случае невыполнения пользователем данных обязательств
                            Компания может приостановить или отказаться от предоставления сервисов в соответствующей
                            части на основании правомочий, предусмотренных гражданским законодательством Российской
                            Федерации.
                            <br/>
                            <br/>
                            3.4. Компания предоставляет сервисы при наличии возможности их предоставления, которая
                            определяется, в том числе, соблюдением пользователями правил Услуги 24/7. Несоблюдение
                            Правил Услуги 24/7 может привести к невозможности исполнения обязательств Компании по
                            предоставлению соответствующих сервисов.
                            <br/>
                            <br/>
                            3.5. В силу характера предлагаемых Компанией сервисов, направленных на удовлетворение
                            индивидуальных потребностей пользователей, Компания не обязана предоставлять услуги каждому
                            обратившемуся лицу и может отказать в предоставлении сервисов. Например, в случаях, когда
                            предоставление сервисов не соответствует правилам Услуги 24/7, его тематике, категориям и
                            т.д.
                            <br/>
                            <br/>
                            3.6. Некоторые сервисы на Услуги 24/7 могут быть доступны только для отдельных категорий
                            пользователей, например, использующих Услуги 24/7 для осуществления своей
                            предпринимательской деятельности.
                            <br/>
                            <br/>
                            3.7. Условия предоставления конкретных сервисов в рамках Договора, в том числе их
                            содержание, объем и сроки определяются в соответствующих Условиях сервисов и других правилах
                            Услуги 24/7.
                            <br/>
                            <br/>
                            3.8. Предоставляемые на Услуги 24/7 сервисы могут изменяться, дополняться, обновляться, в
                            связи с чем их использование предлагается в режиме «как есть», то есть в том виде и объеме,
                            в каком они предоставляются Компанией в момент обращения пользователей к сервисам
                            Услуги 24/7.
                            <br/>
                            <br/>
                            3.9. Компания вправе направлять вам по имеющимся в вашем профиле контактным данным
                            уведомления, связанные с исполнением настоящего Договора или любой из заключенных в рамках
                            Договора сделок, включая оповещения о статусе предоставления сервиса, способах продления,
                            изменениях условий предоставления сервиса и иные информационные сообщения,
                            посредством смс- и push-сообщений.
                            <br/>
                            <br/>
                            3.10. Вы обязуетесь соблюдать при пользовании сервисами требования действующего
                            законодательства Российской Федерации, положения Договора, соответствующие условия
                            выбранного сервиса и все иные правила Услуги 24/7.
                            <br/>
                            <br/>
                            3.11. По требованию Компании вы обязаны предоставить информацию, необходимую для
                            идентификации пользователя в качестве стороны Договора. Это может включать номер телефона и
                            город проживания или город выполнения услуг. Компания не запрашивает дополнительные данные,
                            за исключением указанных выше, и использует их исключительно для целей, связанных с
                            исполнением настоящего Договора.
                            <br/>
                            <br/>
                            3.12. Стороны признают действия, совершенные с использованием номера телефона пользователя
                            и его подтверждения через Telegram, действиями, совершенными пользователем и имеющими силу
                            простой электронной подписи. Логин и пароль не используются.
                        </span>
                    </div>
                    <div className="privacy-policy__column" id="section4">
                        <h2 className="privacy-policy__content-title">4. Стоимость и порядок расчетов</h2>
                        <span className="privacy-policy__content-text">
                            4.1.  Стоимость выбранного пользователем сервиса Услуги 24/7 определяется исходя из
                            категории и города, в котором размещается объявление для заказчиков, либо выполняются услуги
                            для исполнителей. Стоимость размещения объявления для заказчика зависит от категории и
                            города. Исполнители оплачивают подписку на доступ к объявлениям в выбранной категории на
                            один год, стоимость которой также зависит от категории и города. Все цены отображаются в
                            рублях в интерфейсе Услуги 24/7 при оформлении заказа, если иное не предусмотрено условиями
                            соответствующего сервиса. Стоимость включает в себя НДС по ставке, установленной действующим
                            законодательством Российской Федерации. Оплата осуществляется по ценам, действующим на
                            момент совершения платежа.
                            <br/>
                            <br/>
                            4.2. Вы можете совершить оплату одним из способов, представленных в интерфейсе Услуги 24/7
                            в момент оформления заказа.
                            <br/>
                            <br/>
                            4.3. Привязка банковской карты к профилю и последующее использование этой карты для оплаты
                            не предусмотрены. Все платежи осуществляются единовременно на момент заказа услуги.
                            <br/>
                            <br/>
                            4.4. ЮР лица и ИП должны осуществлять оплату по Договору безналичным переводом денежных
                            средств на расчетный счет Компании на основании выставленного счета. Оплата корпоративной
                            банковской картой также возможна.
                            <br/>
                            <br/>
                            4.5. Оплата безналичным переводом по выставленному счету должна производиться с указанием
                            номера профиля пользователя и других реквизитов, идентифицирующих платеж. Неправильное
                            указание данных может привести к некорректному исполнению обязательств по оплате.
                            <br/>
                            <br/>
                            4.6. Заказ должен быть оплачен полностью одним способом платежа.
                            <br/>
                            <br/>
                            4.7. Факт предоставления сервисов и их объем определяются на основании данных учетной системы Компании.
                            <br/>
                            <br/>
                            4.8. Компания предоставляет пользователям юридических лиц и индивидуальных предпринимателей
                            копии универсального передаточного документа (далее – «<b>УПД</b>») в Telegram или через профиль
                            ежемесячно. Оригиналы могут быть направлены почтой по прямому обращению в тех. поддержку.
                            <br/>
                            <br/>
                            4.9. Компания предоставляет пользователям юридических лиц и индивидуальных предпринимателей
                            копии универсального передаточного документа (УПД) в Telegram или через профиль
                            ежемесячно. Оригиналы могут быть направлены почтой по прямому обращению в тех. поддержку.
                            <br/>
                            <br/>
                            4.10. Если пользователь не предоставляет письменные возражения в течение 5 дней после
                            завершения отчетного месяца, услуги считаются оказанными надлежащим образом.
                            <br/>
                            <br/>
                            4.11. Ответственность за получение документов (включая УПД) лежит на пользователе.
                            Компания не несет ответственности за задержку или неполучение документов по причинам,
                            не зависящим от нее.
                            <br/>
                            <br/>
                            4.12. Информация, указываемая в платежных документах, соответствует реквизитам, указанным
                            пользователем в его профиле.
                            <br/>
                            <br/>
                            4.13.  Пользователь несет ответственность за правильность данных в профиле и обязан
                            своевременно обновлять их.
                            <br/>
                            <br/>
                            4.14. Компания вправе проверять правильность указанных в профиле реквизитов и
                            корректировать их при необходимости.
                        </span>
                    </div>
                    <div className="privacy-policy__column" id="section5">
                        <h2 className="privacy-policy__content-title">5. Ответственность</h2>
                        <span className="privacy-policy__content-text">
                            5.1. Компания обязуется предпринимать все разумные усилия для надлежащего предоставления
                            сервисов, однако не несет ответственности и не компенсирует убытки пользователя в случае,
                            если пользователь не может воспользоваться сервисами по следующим причинам:
                            <br/>
                            <br/>
                            — технологические неисправности каналов связи общего пользования, посредством которых
                            осуществляется доступ к сервисам, утраты пользователем доступа в интернет по любой
                            причине, ошибки, пропуски, перерывы в работе или передаче данных, дефекты линий связи и
                            иные технические сбои;
                            <br/>
                            <br/>
                            — несанкционированное вмешательство в работу Услуги 24/7 третьих лиц, включая хакерские,
                            DDoS-атаки, действия вирусных программ и иные нарушения работы Услуги 24/7;
                            <br/>
                            <br/>
                            — при наступлении обстоятельств непреодолимой силы.
                            <br/>
                            <br/>
                            5.2. В случае причинения убытков пользователю по вине Компании, ответственность определяется
                            в порядке, предусмотренном законодательством Российской Федерации. При этом ответственность
                            Компании перед пользователями, использующими Услуги 24/7 для предпринимательской и иной
                            профессиональной деятельности, ограничивается суммой, не превышающей стоимость заказанного
                            и оплаченного пользователем, но не полученного по вине Компании сервиса или, если применимо,
                            размером остатка на балансе, в случае потери средств по вине Компании.
                            <br/>
                            <br/>
                            5.3. Компания не отвечает перед пользователем за убытки, возникшие у пользователя не по вине
                            Компании, в том числе в связи с нарушением пользователем правил Услуги 24/7.
                            <br/>
                            <br/>
                            5.4. Компания не отвечает за перевод или поступление денежных средств пользователя в оплату
                            сервисов Услуги 24/7. Безопасность, конфиденциальность, а также иные условия использования
                            выбранных вами способов оплаты определяются соглашениями между пользователем и
                            соответствующими организациями по приему платежей.
                            <br/>
                            <br/>
                            5.5. Вы несете ответственность за любые действия, совершенные на Услуги 24/7 с
                            использованием вашего номера телефона и подтверждения через Telegram (в том числе за
                            действия работников и третьих лиц) и за убытки, которые могут возникнуть вследствие
                            несанкционированного использования вашего профиля.
                            <br/>
                            <br/>
                            5.6. В случае кражи или утраты данных для входа и подтверждения через Telegram, вы обязаны
                            самостоятельно предпринять необходимые меры для восстановления контроля над профилем.
                            Компания не несет ответственности за действия третьих лиц, приведшие к утрате данных, а
                            также за любые убытки или упущенную выгоду, возникшие в связи с несанкционированным доступом
                            к вашему профилю.
                            <br/>
                            <br/>
                            5.7. Компания не гарантирует соответствие сервисов Услуги 24/7 ожиданиям пользователя и
                            получение пользователем откликов на размещенные объявления, несмотря на использование
                            сервисов Услуги 24/7.
                            <br/>
                            <br/>
                            5.8. В случае отмены заказа услуги Услуги 24/7 до начала её предоставления, или прекращения
                            по любому иному основанию, сумма, уплаченная пользователем, будет возвращена на карту, с
                            которой был произведен платеж, в полном объеме, за исключением случаев, когда указанные
                            условия услуги предусматривают иное.
                        </span>
                    </div>
                    <div className="privacy-policy__column" id="section6">
                        <h2 className="privacy-policy__content-title">6. Рассмотрение претензий</h2>
                        <span className="privacy-policy__content-text">
                            6.1. Споры, возникшие в рамках настоящего Договора, должны быть переданы на рассмотрение в
                            Арбитражный суд Омской области. В случае если спор не подлежит рассмотрению в арбитражном суде в
                            соответствии с его компетенцией, спор будет рассматриваться в иных судах, согласно
                            положениям законодательства Российской Федерации.
                            <br/>
                            <br/>
                            6.2. До обращения в суд пользователь с профилем юридического лица или индивидуального
                            предпринимателя и/или пользователь, осуществляющий коммерческую деятельность на Услуги 24/7,
                            обязан соблюсти внесудебный порядок разрешения споров. Для этого он должен направить в
                            адрес Компании письменную претензию, с приложением документов, подтверждающих обстоятельства
                            и требования, на которые ссылается пользователь. Срок рассмотрения таких претензий
                            составляет не более 30 дней с момента получения претензии Компанией.
                        </span>
                    </div>
                    <div className="privacy-policy__column" id="section7">
                        <h2 className="privacy-policy__content-title">7. Прочие условия</h2>
                        <span className="privacy-policy__content-text">
                            7.1. Договор вступает в силу с момента его заключения в порядке, указанном в разделе 2
                            оферты, и действует в течение неопределенного срока до момента его прекращения в
                            соответствии с Договором и законодательством Российской Федерации.
                            <br/>
                            <br/>
                            7.2. Компания вправе изменять и/или дополнять оферту в пределах, допустимых
                            законодательством Российской Федерации, а также отозвать оферту в любое время.
                            Датой изменения оферты считается дата публикации новой редакции оферты на Услуги 24/7.
                            Вы обязаны ознакомиться с действующей редакцией оферты, размещенной на Услуги 24/7, и с
                            условиями каждой сделки до заключения Договора и совершения сделки.
                            <br/>
                            <br/>
                            7.3. Заказ сервисов Услуги 24/7 и совершение пользователем сделки в рамках Договора после
                            вступления в силу изменений в оферте означают, что стороны согласны с внесением
                            соответствующих изменений и/или дополнений в Договор. Такие изменения в Договоре не
                            применяются к сервисам, заказанным и оплаченным пользователем до вступления в силу изменений
                            в оферте.
                            <br/>
                            <br/>
                            7.4. Компания вправе приостановить исполнение своих обязательств в случае нарушения
                            пользователем встречных обязательств, предусмотренных Договором. В случае невозможности
                            исполнения своих обязательств Компания может прекратить исполнение обязательств с
                            уведомлением об этом пользователя через интерфейс Услуги 24/7 или в Telegram.
                            <br/>
                            <br/>
                            7.5. оговор может быть прекращен досрочно по инициативе пользователя. Если пользователь не
                            воспользовался профилем на Услуги 24/7 в течение 3 лет, Договор считается прекращенным по
                            инициативе пользователя по истечении указанного срока. При прекращении действия Договора вы
                            вправе обратиться к Компании за возвратом неиспользованного остатка денежных средств на
                            основании письменного заявления по установленной Компанией форме. Возврат средств
                            осуществляется на средство платежа, с помощью которого был произведен платеж, а в случае
                            невозможности использования данного средства платежа – иным определенным Компанией способом.
                            <br/>
                            <br/>
                            7.6.  При обращении за возвратом неиспользованного остатка денежных средств пользователь по
                            требованию Компании обязан идентифицировать себя в качестве стороны по Договору, выполнив
                            указанные в инструкциях Компании действия (направить заявление с адреса электронной почты,
                            указанного в профиле, использовать номер телефона, подтвержденный в порядке, предусмотренном
                            Услуги 24/7 и др.), а также предоставить необходимые документы и информацию (в том числе
                            документы, подтверждающие факт внесения платежей).
                            <br/>
                            <br/>
                            7.7. Компания вправе указывать наименование, товарный знак и общее описание услуг
                            пользователя с профилем юридического лица или индивидуального предпринимателя в своих
                            маркетинговых материалах.
                            <br/>
                            <br/>
                            7.8. ДДоговор представляет собой полное соглашение сторон по его предмету и заменяет собой
                            все предыдущие договоренности, заверения и соглашения между сторонами, если иное прямо не
                            согласовано сторонами.
                            <br/>
                            <br/>
                            7.9. Обязательства сторон по Договору, которые по своей природе должны продолжать
                            действовать (включая обязательства по предоставленным гарантиям, конфиденциальности,
                            проведению взаиморасчетов и др.), остаются в силе после окончания срока действия Договора
                            до полного исполнения.
                            <br/>
                            <br/>
                            7.10. Отношения Компании и пользователя по настоящему Договору регулируются
                            законодательством Российской Федерации, если иное прямо не указано.
                        </span>
                    </div>
                    <div className="privacy-policy__column" id="section8">
                        <h2 className="privacy-policy__content-title">8. Реквизиты Компании</h2>
                        <span className="privacy-policy__content-text">
                            <b>Реквизиты компании:</b>
                            <br/>
                            <br/>
                            Индивидуальный предприниматель Щербинин Николай Владимирович
                            <br/>
                            <br/>
                            ИНН: 550403079841
                            <br/>
                            <br/>
                            <b>Банковские реквизиты:</b>
                            <br/>
                            <br/>
                            Название банка: ООО "Банк Точка"
                            <br/>
                            <br/>
                            БИК: 044525104
                            <br/>
                            <br/>
                            Корреспондентский счёт: 30101810745374525104
                            <br/>
                            <br/>
                            Расчётный счёт: 40802810301500416381
                        </span>
                    </div>
                </div>
            </div>
            {scroll > 0 && (
                <button className="btn-up" onClick={scrollToTop}>⇪</button>
            )}
            <Footer/>
        </>
    )
}

export default ServicesAgreement